/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateUpsellDealLogDto } from '../model/';
// @ts-ignore
import { CrmUpsellLogs } from '../model/';
// @ts-ignore
import { DealOwnerDto } from '../model/';
// @ts-ignore
import { DealsFilterDto } from '../model/';
// @ts-ignore
import { LatestDealDto } from '../model/';
// @ts-ignore
import { RoleBasedDealsAggregationDto } from '../model/';
// @ts-ignore
import { RoleBasedUpsellDealsPaginationDto } from '../model/';
/**
 * UpsellDealsApi - axios parameter creator
 * @export
 */
export const UpsellDealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerAllDealsAggregationCount: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerAllDealsAggregationCount', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/all-deals-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerAllDealsAggregationCountForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerAllDealsAggregationCountForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/all-deals-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Peripheral Upsell Deal Log
         * @param {CreateUpsellDealLogDto} createUpsellDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerCreatePeripheralUpsellLog: async (createUpsellDealLogDto: CreateUpsellDealLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUpsellDealLogDto' is not null or undefined
            assertParamExists('upsellDealsControllerCreatePeripheralUpsellLog', 'createUpsellDealLogDto', createUpsellDealLogDto)
            const localVarPath = `/api/upsell-deals/create-peripheral-upsell-deal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpsellDealLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Upsell Deal Log
         * @param {CreateUpsellDealLogDto} createUpsellDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerCreateUpsellLog: async (createUpsellDealLogDto: CreateUpsellDealLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUpsellDealLogDto' is not null or undefined
            assertParamExists('upsellDealsControllerCreateUpsellLog', 'createUpsellDealLogDto', createUpsellDealLogDto)
            const localVarPath = `/api/upsell-deals/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpsellDealLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deal History Logs
         * @param {string} id 
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealHistoryLogs: async (id: string, source: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('upsellDealsControllerDealHistoryLogs', 'id', id)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('upsellDealsControllerDealHistoryLogs', 'source', source)
            const localVarPath = `/api/upsell-deals/{id}/deal-history-logs/{source}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealOwners: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('upsellDealsControllerDealOwners', 'source', source)
            const localVarPath = `/api/upsell-deals/{source}/deal-owners`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Unique Deal Pipelines
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealPipelines: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('upsellDealsControllerDealPipelines', 'source', source)
            const localVarPath = `/api/upsell-deals/{source}/pipeline-names`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Unique Deal Statuses
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealStatuses: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('upsellDealsControllerDealStatuses', 'source', source)
            const localVarPath = `/api/upsell-deals/{source}/deal-status`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Latest Upsell Deal Log by Deal ID
         * @param {string} dealId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerFindLatest: async (dealId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('upsellDealsControllerFindLatest', 'dealId', dealId)
            const localVarPath = `/api/upsell-deals/{deal_id}`
                .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated All Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedAllDeals: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPaginatedAllDeals', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/paginated-all-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated All Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedAllDealsForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPaginatedAllDealsForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/paginated-all-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Peripheral Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedPeripheralDeals: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPaginatedPeripheralDeals', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/paginated-peripheral-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Peripheral Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedPeripheralDealsForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPaginatedPeripheralDealsForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/paginated-peripheral-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated ZapScale Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedZsDeals: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPaginatedZsDeals', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/paginated-zs-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated ZapScale Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedZsDealsForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPaginatedZsDealsForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/paginated-zs-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deal History Logs For C2
         * @param {string} id 
         * @param {string} source 
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2: async (id: string, source: string, dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2', 'id', id)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2', 'source', source)
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/{id}/deal-history-logs-c2/{source}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPeripheralDealsAggregationCount: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPeripheralDealsAggregationCount', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/peripheral-deals-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPeripheralDealsAggregationCountForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerPeripheralDealsAggregationCountForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/peripheral-deals-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerZsDealsAggregationCount: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerZsDealsAggregationCount', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/zs-deals-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerZsDealsAggregationCountForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('upsellDealsControllerZsDealsAggregationCountForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/upsell-deals/zs-deals-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpsellDealsApi - functional programming interface
 * @export
 */
export const UpsellDealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpsellDealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary All Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerAllDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerAllDealsAggregationCount(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary All Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerAllDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerAllDealsAggregationCountForC2(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Peripheral Upsell Deal Log
         * @param {CreateUpsellDealLogDto} createUpsellDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerCreatePeripheralUpsellLog(createUpsellDealLogDto: CreateUpsellDealLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmUpsellLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerCreatePeripheralUpsellLog(createUpsellDealLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Upsell Deal Log
         * @param {CreateUpsellDealLogDto} createUpsellDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerCreateUpsellLog(createUpsellDealLogDto: CreateUpsellDealLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmUpsellLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerCreateUpsellLog(createUpsellDealLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deal History Logs
         * @param {string} id 
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerDealHistoryLogs(id: string, source: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerDealHistoryLogs(id, source, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerDealOwners(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealOwnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerDealOwners(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Unique Deal Pipelines
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerDealPipelines(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerDealPipelines(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Unique Deal Statuses
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerDealStatuses(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerDealStatuses(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Latest Upsell Deal Log by Deal ID
         * @param {string} dealId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerFindLatest(dealId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestDealDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerFindLatest(dealId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated All Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPaginatedAllDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPaginatedAllDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated All Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPaginatedAllDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPaginatedAllDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Peripheral Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPaginatedPeripheralDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPaginatedPeripheralDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Peripheral Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated ZapScale Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPaginatedZsDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPaginatedZsDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated ZapScale Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPaginatedZsDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPaginatedZsDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deal History Logs For C2
         * @param {string} id 
         * @param {string} source 
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id: string, source: string, dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedUpsellDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id, source, dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPeripheralDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPeripheralDealsAggregationCount(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerZsDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerZsDealsAggregationCount(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellDealsControllerZsDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellDealsControllerZsDealsAggregationCountForC2(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpsellDealsApi - factory interface
 * @export
 */
export const UpsellDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpsellDealsApiFp(configuration)
    return {
        /**
         * 
         * @summary All Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerAllDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.upsellDealsControllerAllDealsAggregationCount(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerAllDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.upsellDealsControllerAllDealsAggregationCountForC2(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Peripheral Upsell Deal Log
         * @param {CreateUpsellDealLogDto} createUpsellDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerCreatePeripheralUpsellLog(createUpsellDealLogDto: CreateUpsellDealLogDto, xTenantId?: string, options?: any): AxiosPromise<CrmUpsellLogs> {
            return localVarFp.upsellDealsControllerCreatePeripheralUpsellLog(createUpsellDealLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Upsell Deal Log
         * @param {CreateUpsellDealLogDto} createUpsellDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerCreateUpsellLog(createUpsellDealLogDto: CreateUpsellDealLogDto, xTenantId?: string, options?: any): AxiosPromise<CrmUpsellLogs> {
            return localVarFp.upsellDealsControllerCreateUpsellLog(createUpsellDealLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deal History Logs
         * @param {string} id 
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealHistoryLogs(id: string, source: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerDealHistoryLogs(id, source, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealOwners(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<DealOwnerDto>> {
            return localVarFp.upsellDealsControllerDealOwners(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Unique Deal Pipelines
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealPipelines(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.upsellDealsControllerDealPipelines(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Unique Deal Statuses
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerDealStatuses(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.upsellDealsControllerDealStatuses(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Latest Upsell Deal Log by Deal ID
         * @param {string} dealId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerFindLatest(dealId: string, xTenantId?: string, options?: any): AxiosPromise<LatestDealDto> {
            return localVarFp.upsellDealsControllerFindLatest(dealId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated All Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedAllDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPaginatedAllDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated All Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedAllDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPaginatedAllDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Peripheral Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedPeripheralDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPaginatedPeripheralDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Peripheral Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated ZapScale Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedZsDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPaginatedZsDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated ZapScale Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPaginatedZsDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPaginatedZsDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deal History Logs For C2
         * @param {string} id 
         * @param {string} source 
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id: string, source: string, dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedUpsellDealsPaginationDto> {
            return localVarFp.upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id, source, dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPeripheralDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.upsellDealsControllerPeripheralDealsAggregationCount(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.upsellDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerZsDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.upsellDealsControllerZsDealsAggregationCount(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellDealsControllerZsDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.upsellDealsControllerZsDealsAggregationCountForC2(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for upsellDealsControllerAllDealsAggregationCount operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountRequest
 */
export interface UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerAllDealsAggregationCount
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerAllDealsAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerAllDealsAggregationCountForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerCreatePeripheralUpsellLog operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerCreatePeripheralUpsellLogRequest
 */
export interface UpsellDealsApiUpsellDealsControllerCreatePeripheralUpsellLogRequest {
    /**
     * 
     * @type {CreateUpsellDealLogDto}
     * @memberof UpsellDealsApiUpsellDealsControllerCreatePeripheralUpsellLog
     */
    readonly createUpsellDealLogDto: CreateUpsellDealLogDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerCreatePeripheralUpsellLog
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerCreateUpsellLog operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerCreateUpsellLogRequest
 */
export interface UpsellDealsApiUpsellDealsControllerCreateUpsellLogRequest {
    /**
     * 
     * @type {CreateUpsellDealLogDto}
     * @memberof UpsellDealsApiUpsellDealsControllerCreateUpsellLog
     */
    readonly createUpsellDealLogDto: CreateUpsellDealLogDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerCreateUpsellLog
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerDealHistoryLogs operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerDealHistoryLogsRequest
 */
export interface UpsellDealsApiUpsellDealsControllerDealHistoryLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealHistoryLogs
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerDealOwners operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerDealOwnersRequest
 */
export interface UpsellDealsApiUpsellDealsControllerDealOwnersRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealOwners
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealOwners
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerDealPipelines operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerDealPipelinesRequest
 */
export interface UpsellDealsApiUpsellDealsControllerDealPipelinesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealPipelines
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealPipelines
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerDealStatuses operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerDealStatusesRequest
 */
export interface UpsellDealsApiUpsellDealsControllerDealStatusesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealStatuses
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerDealStatuses
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerFindLatest operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerFindLatestRequest
 */
export interface UpsellDealsApiUpsellDealsControllerFindLatestRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerFindLatest
     */
    readonly dealId: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerFindLatest
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerPaginatedAllDeals operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPaginatedAllDealsRequest
 */
export interface UpsellDealsApiUpsellDealsControllerPaginatedAllDealsRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDeals
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPaginatedAllDealsForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPaginatedPeripheralDeals operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsRequest
 */
export interface UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDeals
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPaginatedPeripheralDealsForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPaginatedZsDeals operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPaginatedZsDealsRequest
 */
export interface UpsellDealsApiUpsellDealsControllerPaginatedZsDealsRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDeals
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPaginatedZsDealsForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request {
    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly source: string

    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly after?: string
}

/**
 * Request parameters for upsellDealsControllerPeripheralDealsAggregationCount operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountRequest
 */
export interface UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCount
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerPeripheralDealsAggregationCountForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerZsDealsAggregationCount operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountRequest
 */
export interface UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerZsDealsAggregationCount
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerZsDealsAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for upsellDealsControllerZsDealsAggregationCountForC2 operation in UpsellDealsApi.
 * @export
 * @interface UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountForC2Request
 */
export interface UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * UpsellDealsApi - object-oriented interface
 * @export
 * @class UpsellDealsApi
 * @extends {BaseAPI}
 */
export class UpsellDealsApi extends BaseAPI {
    /**
     * 
     * @summary All Deals Aggregation Count
     * @param {UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerAllDealsAggregationCount(requestParameters: UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerAllDealsAggregationCount(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All Deals Aggregation Count C2
     * @param {UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerAllDealsAggregationCountForC2(requestParameters: UpsellDealsApiUpsellDealsControllerAllDealsAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerAllDealsAggregationCountForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Peripheral Upsell Deal Log
     * @param {UpsellDealsApiUpsellDealsControllerCreatePeripheralUpsellLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerCreatePeripheralUpsellLog(requestParameters: UpsellDealsApiUpsellDealsControllerCreatePeripheralUpsellLogRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerCreatePeripheralUpsellLog(requestParameters.createUpsellDealLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Upsell Deal Log
     * @param {UpsellDealsApiUpsellDealsControllerCreateUpsellLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerCreateUpsellLog(requestParameters: UpsellDealsApiUpsellDealsControllerCreateUpsellLogRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerCreateUpsellLog(requestParameters.createUpsellDealLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deal History Logs
     * @param {UpsellDealsApiUpsellDealsControllerDealHistoryLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerDealHistoryLogs(requestParameters: UpsellDealsApiUpsellDealsControllerDealHistoryLogsRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerDealHistoryLogs(requestParameters.id, requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique deal owners
     * @param {UpsellDealsApiUpsellDealsControllerDealOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerDealOwners(requestParameters: UpsellDealsApiUpsellDealsControllerDealOwnersRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerDealOwners(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Unique Deal Pipelines
     * @param {UpsellDealsApiUpsellDealsControllerDealPipelinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerDealPipelines(requestParameters: UpsellDealsApiUpsellDealsControllerDealPipelinesRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerDealPipelines(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Unique Deal Statuses
     * @param {UpsellDealsApiUpsellDealsControllerDealStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerDealStatuses(requestParameters: UpsellDealsApiUpsellDealsControllerDealStatusesRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerDealStatuses(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Latest Upsell Deal Log by Deal ID
     * @param {UpsellDealsApiUpsellDealsControllerFindLatestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerFindLatest(requestParameters: UpsellDealsApiUpsellDealsControllerFindLatestRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerFindLatest(requestParameters.dealId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated All Deals
     * @param {UpsellDealsApiUpsellDealsControllerPaginatedAllDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPaginatedAllDeals(requestParameters: UpsellDealsApiUpsellDealsControllerPaginatedAllDealsRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPaginatedAllDeals(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated All Deals C2
     * @param {UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPaginatedAllDealsForC2(requestParameters: UpsellDealsApiUpsellDealsControllerPaginatedAllDealsForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPaginatedAllDealsForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Peripheral Deals
     * @param {UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPaginatedPeripheralDeals(requestParameters: UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPaginatedPeripheralDeals(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Peripheral Deals C2
     * @param {UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPaginatedPeripheralDealsForC2(requestParameters: UpsellDealsApiUpsellDealsControllerPaginatedPeripheralDealsForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPaginatedPeripheralDealsForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated ZapScale Deals
     * @param {UpsellDealsApiUpsellDealsControllerPaginatedZsDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPaginatedZsDeals(requestParameters: UpsellDealsApiUpsellDealsControllerPaginatedZsDealsRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPaginatedZsDeals(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated ZapScale Deals C2
     * @param {UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPaginatedZsDealsForC2(requestParameters: UpsellDealsApiUpsellDealsControllerPaginatedZsDealsForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPaginatedZsDealsForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deal History Logs For C2
     * @param {UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2(requestParameters: UpsellDealsApiUpsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPeripheralDealHistoryLogsDateWiseForC2(requestParameters.id, requestParameters.source, requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral Deals Aggregation Count
     * @param {UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPeripheralDealsAggregationCount(requestParameters: UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPeripheralDealsAggregationCount(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral Deals Aggregation Count C2
     * @param {UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerPeripheralDealsAggregationCountForC2(requestParameters: UpsellDealsApiUpsellDealsControllerPeripheralDealsAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerPeripheralDealsAggregationCountForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ZapScale Deals Aggregation Count
     * @param {UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerZsDealsAggregationCount(requestParameters: UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountRequest, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerZsDealsAggregationCount(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ZapScale Deals Aggregation Count C2
     * @param {UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpsellDealsApi
     */
    public upsellDealsControllerZsDealsAggregationCountForC2(requestParameters: UpsellDealsApiUpsellDealsControllerZsDealsAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return UpsellDealsApiFp(this.configuration).upsellDealsControllerZsDealsAggregationCountForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
