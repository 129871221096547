import { C2Type, C2TypeNameEnum } from '@/api/client';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { c2TypeApi } from '@/api';

export const useC2TypeStore = defineStore('c2-type', () => {
  const isLoading = ref<boolean>(false);
  const allC2TypeList = ref<C2Type[]>([]);

  async function fetchC2Types() {
    isLoading.value = true;
    try {
      const { data } = await c2TypeApi.c2TypesControllerFindAll();
      allC2TypeList.value = data;
    } catch (e) {
      console.error('c2 Types', e);
    } finally {
      isLoading.value = false;
    }
  }

  const c2Types = computed(() => {
    return allC2TypeList.value.filter((v) => v.name !== C2TypeNameEnum.Churned && !v.is_churned);
  });

  const activeC2Types = computed(() => {
    return allC2TypeList.value.filter((x) => x.is_active && !x.is_churned);
  });

  const activeC2TypesIncludingChurned = computed(() => {
    return allC2TypeList.value.filter((x) => x.is_active);
  });

  function getC2TypeDisplayName(c2TypeId?: string) {
    if (!c2TypeId) {
      return '';
    }
    return allC2TypeList.value.find((x) => x.id === c2TypeId)?.display_name || '';
  }

  return {
    isLoading,
    allC2TypeList,
    c2Types,
    activeC2Types,
    activeC2TypesIncludingChurned,
    fetchC2Types,
    getC2TypeDisplayName,
  };
});
