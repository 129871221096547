/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DealForecast } from '../model/';
// @ts-ignore
import { RearrangeForecastsDto } from '../model/';
/**
 * DealForecastApi - axios parameter creator
 * @export
 */
export const DealForecastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new deal forecast
         * @param {string} name 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerCreate: async (name: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('dealForecastControllerCreate', 'name', name)
            const localVarPath = `/api/deal-forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete forecast
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerDelete: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dealForecastControllerDelete', 'id', id)
            const localVarPath = `/api/deal-forecast/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find All Deal Forecasts
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deal-forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rearrange forecasts
         * @param {RearrangeForecastsDto} rearrangeForecastsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerRearrange: async (rearrangeForecastsDto: RearrangeForecastsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rearrangeForecastsDto' is not null or undefined
            assertParamExists('dealForecastControllerRearrange', 'rearrangeForecastsDto', rearrangeForecastsDto)
            const localVarPath = `/api/deal-forecast/rearrange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rearrangeForecastsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new deal forecast
         * @param {string} id 
         * @param {string} newName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerUpdate: async (id: string, newName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dealForecastControllerUpdate', 'id', id)
            // verify required parameter 'newName' is not null or undefined
            assertParamExists('dealForecastControllerUpdate', 'newName', newName)
            const localVarPath = `/api/deal-forecast/update-name/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newName !== undefined) {
                localVarQueryParameter['new_name'] = newName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealForecastApi - functional programming interface
 * @export
 */
export const DealForecastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealForecastApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new deal forecast
         * @param {string} name 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealForecastControllerCreate(name: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealForecast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealForecastControllerCreate(name, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete forecast
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealForecastControllerDelete(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealForecastControllerDelete(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find All Deal Forecasts
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealForecastControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealForecast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealForecastControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rearrange forecasts
         * @param {RearrangeForecastsDto} rearrangeForecastsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealForecastControllerRearrange(rearrangeForecastsDto: RearrangeForecastsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealForecast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealForecastControllerRearrange(rearrangeForecastsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new deal forecast
         * @param {string} id 
         * @param {string} newName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealForecastControllerUpdate(id: string, newName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealForecastControllerUpdate(id, newName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealForecastApi - factory interface
 * @export
 */
export const DealForecastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealForecastApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new deal forecast
         * @param {string} name 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerCreate(name: string, xTenantId?: string, options?: any): AxiosPromise<Array<DealForecast>> {
            return localVarFp.dealForecastControllerCreate(name, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete forecast
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerDelete(id: string, xTenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.dealForecastControllerDelete(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find All Deal Forecasts
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<DealForecast>> {
            return localVarFp.dealForecastControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rearrange forecasts
         * @param {RearrangeForecastsDto} rearrangeForecastsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerRearrange(rearrangeForecastsDto: RearrangeForecastsDto, xTenantId?: string, options?: any): AxiosPromise<Array<DealForecast>> {
            return localVarFp.dealForecastControllerRearrange(rearrangeForecastsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new deal forecast
         * @param {string} id 
         * @param {string} newName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealForecastControllerUpdate(id: string, newName: string, xTenantId?: string, options?: any): AxiosPromise<DealForecast> {
            return localVarFp.dealForecastControllerUpdate(id, newName, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dealForecastControllerCreate operation in DealForecastApi.
 * @export
 * @interface DealForecastApiDealForecastControllerCreateRequest
 */
export interface DealForecastApiDealForecastControllerCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerCreate
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for dealForecastControllerDelete operation in DealForecastApi.
 * @export
 * @interface DealForecastApiDealForecastControllerDeleteRequest
 */
export interface DealForecastApiDealForecastControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerDelete
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for dealForecastControllerFindAll operation in DealForecastApi.
 * @export
 * @interface DealForecastApiDealForecastControllerFindAllRequest
 */
export interface DealForecastApiDealForecastControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for dealForecastControllerRearrange operation in DealForecastApi.
 * @export
 * @interface DealForecastApiDealForecastControllerRearrangeRequest
 */
export interface DealForecastApiDealForecastControllerRearrangeRequest {
    /**
     * 
     * @type {RearrangeForecastsDto}
     * @memberof DealForecastApiDealForecastControllerRearrange
     */
    readonly rearrangeForecastsDto: RearrangeForecastsDto

    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerRearrange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for dealForecastControllerUpdate operation in DealForecastApi.
 * @export
 * @interface DealForecastApiDealForecastControllerUpdateRequest
 */
export interface DealForecastApiDealForecastControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerUpdate
     */
    readonly newName: string

    /**
     * 
     * @type {string}
     * @memberof DealForecastApiDealForecastControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * DealForecastApi - object-oriented interface
 * @export
 * @class DealForecastApi
 * @extends {BaseAPI}
 */
export class DealForecastApi extends BaseAPI {
    /**
     * 
     * @summary Create new deal forecast
     * @param {DealForecastApiDealForecastControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealForecastApi
     */
    public dealForecastControllerCreate(requestParameters: DealForecastApiDealForecastControllerCreateRequest, options?: AxiosRequestConfig) {
        return DealForecastApiFp(this.configuration).dealForecastControllerCreate(requestParameters.name, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete forecast
     * @param {DealForecastApiDealForecastControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealForecastApi
     */
    public dealForecastControllerDelete(requestParameters: DealForecastApiDealForecastControllerDeleteRequest, options?: AxiosRequestConfig) {
        return DealForecastApiFp(this.configuration).dealForecastControllerDelete(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find All Deal Forecasts
     * @param {DealForecastApiDealForecastControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealForecastApi
     */
    public dealForecastControllerFindAll(requestParameters: DealForecastApiDealForecastControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return DealForecastApiFp(this.configuration).dealForecastControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rearrange forecasts
     * @param {DealForecastApiDealForecastControllerRearrangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealForecastApi
     */
    public dealForecastControllerRearrange(requestParameters: DealForecastApiDealForecastControllerRearrangeRequest, options?: AxiosRequestConfig) {
        return DealForecastApiFp(this.configuration).dealForecastControllerRearrange(requestParameters.rearrangeForecastsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new deal forecast
     * @param {DealForecastApiDealForecastControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealForecastApi
     */
    public dealForecastControllerUpdate(requestParameters: DealForecastApiDealForecastControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DealForecastApiFp(this.configuration).dealForecastControllerUpdate(requestParameters.id, requestParameters.newName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
