<script setup lang="ts">
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { RouteName } from '@/router/route-name';
import { FeatureFlagNames } from '@zapscale/types';
import { computed } from 'vue';

const { isEnabled: isDealsTwoWayEnabled } = useFeatureFlag(FeatureFlagNames.deals_two_way);

let tabs = [
  {
    label: 'Your Profile',
    to: { name: RouteName.Profile },
  },
  {
    label: 'Your Business Info',
    to: { name: RouteName.BusinessInfo },
  },
  {
    label: 'ZapScale Users',
    to: {
      name: RouteName.ZapscaleUsers,
    },
  },
  {
    label: 'SaaS App',
    to: { name: RouteName.SaasApp },
  },
  {
    label: 'Integrate Your Apps',
    to: { name: RouteName.PeripheralApps },
  },
  {
    label: 'Set Up Upsell Plans',
    to: { name: RouteName.UpsellSettings },
    comingSoon: false,
  },
  {
    label: 'Customers And Users',
    to: { name: RouteName.CustomersAndUsers },
  },
];

const tabItems = computed(() => {
  const tabElements = tabs;
  if (isDealsTwoWayEnabled.value) {
    tabElements.push({
      label: 'Deal Configurations',
      to: { name: RouteName.DealForecast },
    });
  }
  return tabElements;
});
</script>

<template>
  <q-tabs
    active-color="primary"
    align="left"
    no-caps
    outside-arrows
    mobile-arrows
    class="tw-h-16 tw-max-w-full tw-bg-light-shade-1 tw-font-bold tw-text-dark-shade-2"
  >
    <q-route-tab v-for="(tab, idx) in tabItems" :key="idx" :to="tab.to" :ripple="false" :disable="tab.comingSoon">
      {{ tab.label }}
      <div v-if="tab.comingSoon" class="tw-text-xs tw-font-semibold !tw-text-dark-shade-4" style="margin-bottom: -1rem">
        Coming Soon
      </div>
    </q-route-tab>
  </q-tabs>
</template>

<style scoped lang="postcss">
:deep(.q-tab) {
  padding: 0 0.125rem;
  margin-right: 3rem;
}

:deep(.q-tab__label) {
  @apply tw-text-lg tw-font-bold;
}
</style>
