import { CustomerColumnTypeEnum } from './enums/customer-column-type.enum';

export const ZAPSCALE_DEFAULT_HEALTH_VIEW = 'ZapScale Default Health View';

export const CSV_DOWNLOAD_JOB_RETRY_COUNT = 5;

export const S3_DOWNLOAD_LINK_RETRY_COUNT = 10;

export const S3_DOWNLOAD_LINK_RETRY_DELAY = 1000;

export const CUSTOMER_LIST_SYSTEM_FIELDS = [
  // customer metadata start
  {
    name: 'c2_name',
    display_name: 'Name',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'zs',
    display_name: 'ZapScore Percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'c2_acv',
    display_name: 'ARR',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'days_to_renewal',
    display_name: 'Days to Renewal',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'c2_type_name',
    display_name: 'Type',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'c2_app_id',
    display_name: 'Customer Id',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'unique_c2_identifier',
    display_name: 'Customer Unique Id',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'churn_probability',
    display_name: 'Churn Probability',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'total_u2s',
    display_name: 'Total Users',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'c2_customer_creation_date',
    display_name: 'Customer Start Date',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'customer_since',
    display_name: 'Customer Since',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'product_name',
    display_name: 'Product',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'subscription_plan_names',
    display_name: 'Subscription Plan Name',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'cs_u1_names',
    display_name: 'CSM',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'account_manager_u1_names',
    display_name: 'Account Executive',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'sales_u1_names',
    display_name: 'Sales Executive',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'c2_address',
    display_name: 'Location',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'segment_names',
    display_name: 'Segment',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'is_starred_c2',
    display_name: 'Star Customer',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  // Customer metadata end

  // Overall health start
  {
    name: 'c2_zapscore',
    display_name: 'Overall Health ZapScore',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'c2zs_color_code',
    display_name: 'ZapScore Color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'c2zs_absolute_percentile_change',
    display_name: 'ZapScore % Change from Last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // Overall health end

  // User health start
  // {
  //   name: 'total_u2s',
  //   display_name: 'No of Users',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number',
  // },
  {
    name: 'percent_u2s_in_green',
    display_name: '% Users in good Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_u2s_in_green',
    display_name: 'No of Users in good health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_u2s_in_yellow',
    display_name: '% Users in OK Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_u2s_in_yellow',
    display_name: 'No of Users in OK health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_u2s_in_red',
    display_name: '% Users in Poor Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_u2s_in_red',
    display_name: 'No of Users in Poor health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_no_of_decision_makers',
    display_name: 'No of Decision Maker Users',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_of_green_decision_makers',
    display_name: '% decision makers in good Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_green_decision_makers',
    display_name: 'No of decision makers in good Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_no_of_influencers',
    display_name: 'No of Influencer Users',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_of_green_influencers',
    display_name: '% influencers in good Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_green_influencers',
    display_name: 'No of Influencers in good Health',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // User health end

  // Product usage health start
  {
    name: 'usage_success_score',
    display_name: 'Overall Product Usage Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'uss',
    display_name: 'Product Usage Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'uss_color_code',
    display_name: 'Product Usage color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'uss_absolute_percentile_change',
    display_name: 'Overall Product Usage % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'stvcs_total_sessions_time',
    display_name: 'Total Time on Product (min)',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'session_time_volume_consistency_score',
    display_name: 'Total Time on Product score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'stvcs',
    display_name: 'Total Time on Product percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'stvcs_color_code',
    display_name: 'Total Time on Product color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'stvcs_absolute_percentile_change',
    display_name: 'Total Time on Product % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'session_time_per_day_per_u2_avg',
    display_name: 'Avg per user time on product (min)',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'pvvcs_total_no_of_page_visits',
    display_name: 'Total Pages Visited',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'page_visits_volume_consistency_score',
    display_name: 'Total Pages Visited score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'pvvcs',
    display_name: 'Total Pages Visited percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pvvcs_color_code',
    display_name: 'Total Pages Visited color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pvvcs_absolute_percentile_change',
    display_name: 'Total Pages Visited  % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'page_visit_per_day_per_u2_avg',
    display_name: 'Avg per user Pages Visited',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'fups_total_features',
    display_name: 'Total Unique Features in the Product',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'fups_total_no_of_unique_features_used',
    display_name: 'No of unique Features Used',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'feature_use_percentage_score',
    display_name: '% Unique Features Used',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'fups',
    display_name: '% Unique Features Used score percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'fups_color_code',
    display_name: '% Unique Features Used score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'fups_absolute_percentile_change',
    display_name: '% Unique Features Used score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // product usage health end

  // ticketing health start
  {
    name: 'bug_tracking_score',
    display_name: 'Overall Ticketing Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'bts',
    display_name: 'Ticketing Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'bts_color_code',
    display_name: 'Ticketing percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'bts_absolute_percentile_change',
    display_name: 'Ticketing Percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_open_tickets',
    display_name: 'No of Open Tickets',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_no_of_bugs', // for customer custom view
    display_name: 'No of Tickets Logged in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_bugs_logged', // for segment custom view
    display_name: 'No of Tickets Logged in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'bug_log_score',
    display_name: '1 - Tickets / hr of usage',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'bls',
    display_name: 'Tickets / hr of usage percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'bls_color_code',
    display_name: 'Tickets / hr of usage color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'bls_absolute_percentile_change',
    display_name: 'Tickets / hr of usage % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_bugs_p1',
    display_name: 'No of P1 Tickets Logged in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'hundred_minus_p1_percentage_bugs',
    display_name: '100 - % of P1 Tickets',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'p1pb',
    display_name: '100 - % of P1 Tickets percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'p1pb_color_code',
    display_name: '100 - % of P1 Tickets color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'p1pb_absolute_percentile_change',
    display_name: '100 - % of P1 Tickets % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_bugs_resolved',
    display_name: 'No of Tickets Resolved in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'bug_resolution_score',
    display_name: '% of resolved Tickets',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'brs',
    display_name: '% resolved Tickets percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'brs_color_code',
    display_name: '% resolved Tickets color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'brs_absolute_percentile_change',
    display_name: '% resolved tickets % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_u2_logging_bugs',
    display_name: 'No of Users Logging Tickets in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'ulpb',
    display_name: '100 - % users logging Tickets percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'ulpb_color_code',
    display_name: '100 - % users logging Tickets color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'ulpb_absolute_percentile_change',
    display_name: '100 - % users logging Tickets % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // ticketing health end

  // feature request health start
  {
    name: 'feature_request_score',
    display_name: 'Overall Feature Request Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'frs',
    display_name: 'Feature Request Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'frs_color_code',
    display_name: 'Feature Request percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'frs_absolute_percentile_change',
    display_name: 'Feature Request Percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_feature_requests',
    display_name: 'No of Feature Request Logged in last 90 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'feature_requests_log_score',
    display_name:
      'Feature in use / (Feature Request logged + in use) = Feature log score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'frls',
    display_name:
      'Feature in use / (Feature Request logged + in use) percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'frls_color_code',
    display_name: 'Feature log score percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'frls_absolute_percentile_change',
    display_name: 'Feature log score percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_features_resolved',
    display_name: 'No of Feature Request Resolved in last 90 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percentage_feature_requests_delivered_score',
    display_name: '% of resolved Feature Request',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'pfrds',
    display_name: '% resolved Feature Request percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pfrds_color_code',
    display_name: '% resolved Feature Request color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pfrds_absolute_percentile_change',
    display_name: '% resolved Feature Request % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // feature request health end

  // billing and invoice health start
  {
    name: 'payment_app_score',
    display_name: 'Overall Billing and Invoice Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'pas',
    display_name: 'Billing and Invoice Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pas_color_code',
    display_name: 'Billing and Invoice percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pas_absolute_percentile_change',
    display_name: 'Billing and Invoice Percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'rolling_no_of_invoices',
    display_name: 'No of Invoices in last 365 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_invoices_paid_full_on_time',
    display_name: 'No of Invoices paid full and on-time',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_full_payment_score',
    display_name: '% of Invoices paid full and on-time',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'pfps',
    display_name: '% of Invoices paid full and on-time percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pfps_color_code',
    display_name: '% of Invoices paid full and on-time percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pfps_absolute_percentile_change',
    display_name: '% of Invoices paid full and on-time % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'rolling_invoice_value_due',
    display_name: 'Invoiced Amount',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // {
  //   name: 'rolling_invoice_value_due',
  //   display_name: 'Total Amount Due',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number',
  // },
  {
    name: 'rolling_invoice_value_paid',
    display_name: 'Total Amount Paid',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'total_amount_due',
    display_name: 'Total Amount Due',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_dues_cleared_score',
    display_name: '% Total Amount Paid',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'pdcs',
    display_name: '% Total Amount Paid percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pdcs_color_code',
    display_name: '% Total Amount Paid percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pdcs_absolute_percentile_change',
    display_name: '% Total Amount Paid Percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_invoices_overdue_90_days_plus',
    display_name: 'No of Invoices unpaid for 90+ days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percentage_of_overdue',
    display_name: '% Invoices unpaid for 90+ days',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'ppos',
    display_name: '% Invoices unpaid for 90+ days percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'ppos_color_code',
    display_name: '% Invoices unpaid for 90+ days percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'ppos_absolute_percentile_change',
    display_name: '% Invoices unpaid for 90+ days % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // billing health end

  // communication health start
  {
    name: 'customer_communication_score',
    display_name: 'Overall Communication Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'ccs',
    display_name: 'Communication Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'ccs_color_code',
    display_name: 'Communication percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'ccs_absolute_percentile_change',
    display_name: 'Communication Score Percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'no_of_emails_or_communication',
    display_name: 'No of comms in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'communication_frequency_score',
    display_name: 'No of comms in last 30 days percentile score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'cfs_color_code',
    display_name: 'No of comms in last 30 days percentile score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'cfs_absolute_percentile_change',
    display_name:
      'No of comms in last 30 days percentile score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'days_since_last_communication',
    display_name: 'Days since last comms',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'dslcwc',
    display_name: '100 - Days since last comms percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'dslcwc_color_code',
    display_name: '100 - Days since last comms percentile score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'dslcwc_absolute_percentile_change',
    display_name:
      '100 - Days since last comms percentile score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'intercom_cfs_no_of_communication',
    display_name: 'No of intercom messages in last 30 days',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'intercom_cfs_score',
    display_name: 'No of intercom messages percentile score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'intercom_cfs_color_code',
    display_name: 'No of intercom messages percentile score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'intercom_cfs_absolute_percentile_change',
    display_name: 'No of intercom messages percentile score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // communication health end

  // upsell health start
  {
    name: 'upsell_success_score',
    display_name: 'Overall Upsell Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'upss',
    display_name: 'Upsell Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'upss_color_code',
    display_name: 'Upsell percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'upss_absolute_percentile_change',
    display_name: 'Upsell Score Percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'entitlement_object_high_limit',
    display_name: 'Subscription Limit for this customer',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_user_enttilement_reached_score',
    display_name: '% subscription used',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'puers',
    display_name: '% subscription used percentile',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'puers_color_code',
    display_name: '% subscription used percentile color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'puers_absolute_percentile_change',
    display_name: '% subscription used percentile % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'four_week_percent_user_growth_score',
    display_name: 'Last 30 days Subscription growth percentage',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'wmglqp',
    display_name:
      'Last 30 days Subscription growth percentage percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'wmglqp_color_code',
    display_name:
      'Last 30 days Subscription growth percentage percentile score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'wmglqp_absolute_percentile_change',
    display_name:
      'Last 30 days Subscription growth percentage percentile score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_decision_makers_in_good_health_score',
    display_name: '% of decision makers in good health',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'pdmghs',
    display_name: '% of decision makers in good health percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pdmghs_color_code',
    display_name:
      '% of decision makers in good health percentile score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pdmghs_absolute_percentile_change',
    display_name:
      '% of decision makers in good health percentile score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'percent_influencers_in_good_health_score',
    display_name: '% of Influencers in good health',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'pighs',
    display_name: '% of Influencers in good health percentile score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'pighs_color_code',
    display_name: '% of Influencers in good health percentile score color code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'pighs_absolute_percentile_change',
    display_name:
      '% of Influencers in good health percentile score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // upsell health end

  // cs gut feel health start
  {
    name: 'handfeel_score',
    display_name: 'CS Gut Feel Score',
    source: CustomerColumnTypeEnum.KpiScore,
    value_type: 'number',
  },
  {
    name: 'hfs',
    display_name: 'CS Gut Feel Percentile Score',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'hfs_color_code',
    display_name: 'CS Gut Feel Percentile Score Color Code',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'hfs_absolute_percentile_change',
    display_name: 'CS Gut Feel Percentile Score % change from last',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  // cs gut feel health end

  //additional segment list view columns
  {
    name: 'name',
    display_name: 'Segment',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'c2_segment_zapscore',
    display_name: 'ZapScore',
    source: CustomerColumnTypeEnum.SystemKpi,
    value_type: 'number',
  },
  {
    name: 'total_c2_acv',
    display_name: 'ARR',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },

  //new columns for c2 list screen
  {
    name: 'last_renewal_date',
    display_name: 'Last Renewal Date',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'next_renewal_date',
    display_name: 'Next Renewal Date',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },
  {
    name: 'handfeel_score',
    display_name: 'CS GutFeel Score',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'handfeel_score_updated_at',
    display_name: 'CS Gutfeel Score Updated At',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },

  //tasks view start
  {
    name: 'open_task_count',
    display_name: 'Total Task Open as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'overdue_task_count',
    display_name: 'Total Overdue Tasks as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'working_task_count',
    display_name: 'Total WIP Tasks as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'new_task_count',
    display_name: 'New Tasks Added In This Period',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'done_task_count',
    display_name: 'Tasks Closed/Completed In This Period',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  //tasks view end
  //additional column for tasks view start
  //zapscale defined tasks
  {
    name: 'zapscale_defined_open_task_count',
    display_name: 'Total Zapscale Defined Task Open as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'zapscale_defined_overdue_task_count',
    display_name: 'Total Zapscale Defined Overdue Tasks as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'zapscale_defined_working_task_count',
    display_name: 'Total Zapscale Defined WIP Tasks as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'zapscale_defined_new_task_count',
    display_name: 'New Zapscale Defined Tasks Added In This Period',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'zapscale_defined_done_task_count',
    display_name: 'Zapscale Defined Tasks Closed/Completed In This Period',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  //manual tasks
  {
    name: 'manual_open_task_count',
    display_name: 'Total Manual Task Open as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'manual_overdue_task_count',
    display_name: 'Total Manual Overdue Tasks as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'manual_working_task_count',
    display_name: 'Total Manual WIP Tasks as of Today',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'manual_new_task_count',
    display_name: 'New Manual Tasks Added In This Period',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  },
  {
    name: 'manual_done_task_count',
    display_name: 'Manual Tasks Closed/Completed In This Period',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'number',
  }, 
  //additional columns for tasks view end

  //action view columns start
  {
    name: 'total_actions',
    display_name: 'Total Actions',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number', 
  },
  {
    name: 'total_time_logged',
    display_name: 'Total Time Logged',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number', 
  },
  {
    name: 'days_since_last_action',
    display_name: 'Days Since Any Action Logged',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number', 
  },
  {
    name: 'last_action_date',
    display_name: 'Last Action date',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'string', 
  },
  // {
  //   name: 'total_meeting_actions',
  //   display_name: 'Total Meetings Logged',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number', 
  // },
  // {
  //   name: 'total_meeting_time_logged',
  //   display_name: 'Total Meetings Time Logged',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number', 
  // },
  // {
  //   name: 'days_since_last_meeting_action',
  //   display_name: 'Days Since Last Meeting',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number', 
  // },
  // {
  //   name: 'last_meeting_action_date',
  //   display_name: 'Last Meeting Date',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'string', 
  // },
  // {
  //   name: 'total_qbr_meeting_actions',
  //   display_name: 'Total QBRs Logged',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number',  
  // },
  // {
  //   name: 'total_qbr_meeting_time_logged',
  //   display_name: 'Total QBRs Time Logged',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number', 
  // },
  // {
  //   name: 'days_since_last_qbr_meeting_action', 
  //   display_name: 'Days Since Last QBR',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'number', 
  // },
  // {
  //   name: 'last_qbr_meeting_action_date',
  //   display_name: 'Last QBR Date',
  //   source: CustomerColumnTypeEnum.SystemField,
  //   value_type: 'string', 
  // },

  //------
  
  {
    name: 'meeting_total_logged',
    display_name: 'Total Meeting Logged',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'meeting_total_time_logged',
    display_name: 'Total Meeting Time Logged',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'meeting_days_since_last',
    display_name: 'Days Since Last Meeting',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'meeting_last_date',
    display_name: 'Last Meeting Date',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'string',
  },
  {
    name: 'qbr_meeting_total_logged',
    display_name: 'Total QBR Meeting Logged',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'qbr_meeting_total_time_logged',
    display_name: 'Total QBR Meeting Time Logged',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'qbr_meeting_days_since_last',
    display_name: 'Days Since Last QBR Meeting',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'qbr_meeting_last_date',
    display_name: 'Last QBR Meeting Date',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'string',
  },
  //action view columns end

  {
    name: 'churned_at',
    display_name: 'Churned Date',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },

  //action view 'in-this-period' columns start
  {
    name: 'total_actions_logged_in_this_period',
    display_name: 'Total Actions Logged In This Period',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number', 
  },
  {
    name: 'total_actions_time_logged_in_this_period',
    display_name: 'Total Time Logged In Actions In This Period',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number', 
  },
  //meeting
  {
    name: 'meeting_total_logged_in_this_period',
    display_name: 'Total Meeting Logged In This Period',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'meeting_total_time_logged_in_this_period',
    display_name: 'Total Time Logged In Meeting In This Period',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  //qbr_meeting
  {
    name: 'qbr_meeting_total_logged_in_this_period',
    display_name: 'Total QBR Meeting Logged In This Period',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  {
    name: 'qbr_meeting_total_time_logged_in_this_period',
    display_name: 'Total Time Logged In QBR Meeting In This Period',
    source: CustomerColumnTypeEnum.Action,
    value_type: 'number',
  },
  //action view 'in-this-period' columns end
  {
    name: 'gutfeel_comments',
    display_name: 'Last CS Gutfeel Comment',
    source: CustomerColumnTypeEnum.SystemField,
    value_type: 'string',
  },


];
