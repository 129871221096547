import { Ref, computed } from 'vue';
import { queryOptions, useQuery } from '@tanstack/vue-query';
import { inboxApi } from '@/api';
import { DateInfo } from '@/api/client/model/date-info';
import { C1Master } from '@/api/client/model/c1-master';

export const inboxQueryKeys = {
  all: () => ['list-inbox'] as const,
  one: (id: Ref<string>) => ['inbox', { id }] as const,
  threadList: (inboxId: Ref<string>, label: Ref<string>, page: Ref<number>) =>
    ['thread-list', { inboxId: inboxId, label: label, page: page }] as const,
  threadDetails: (threadId: Ref<string>, label: Ref<string>) =>
    ['thread-details', { id: threadId, label: label }] as const,
  inboxStats: (id: Ref<string>) => ['inbox-stats', { id }] as const,
  attachmentList: (inboxId: Ref<string>, messageId: Ref<string>) =>
    ['attachment-list', { inboxId: inboxId, messageId: messageId }] as const,
  inboxLabels: (id: Ref<string>) => ['inbox-labels', { id }] as const,
  userSignature: (id: Ref<string>) => ['user-signature', { id }] as const,
};

export const useFetchInboxStatsQuery = (inboxId: Ref<string>) => {
  const enabled = computed(() => !!inboxId.value);
  return useQuery({
    queryKey: inboxQueryKeys.inboxStats(inboxId),
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerInboxStats({
        id: inboxId.value,
      });
      return data;
    },
    enabled,
    refetchInterval: 10000,
  });
};

export const useFetchInboxListQuery = () => {
  return useQuery({
    queryKey: inboxQueryKeys.all(),
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerFindAll();
      return data;
    },
  });
};

export const useFetchInboxQuery = (id: Ref<string>) => {
  return useQuery({
    queryKey: inboxQueryKeys.one(id),
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerFindOne({ id: id.value });
      return data;
    },
  });
};

export const useFetchThreadListQuery = (
  inboxId: Ref<string>,
  label: Ref<string>,
  page: Ref<number>,
  tag: Ref<string>,
  tab?: Ref<string>,
) => {
  const enabled = computed(() => !!inboxId.value && !!label.value);

  return queryOptions({
    queryKey: ['thread-list', { inboxId: inboxId, label: label, page: page, tag: tag, tab: tab }] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerThreadList({
        id: inboxId.value,
        label: label.value,
        page: page?.value,
        tag: tag?.value,
        tab: tab?.value ?? '',
      });
      return data;
    },
    enabled,
    refetchInterval: 10000,
  });
};

export const useFetchThreadDetailsQuery = (inboxId: Ref<string>, threadId: Ref<string>, labelId: Ref<string>) => {
  const enabled = computed(() => !!inboxId.value && !!threadId.value && !!labelId.value);

  return queryOptions({
    queryKey: ['thread-details', { id: threadId, label: labelId }] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerThreadDetails({
        id: inboxId.value,
        threadId: threadId.value,
        labelId: labelId.value,
      });
      return data;
    },
    enabled,
  });
};

export const useFetchAttachmentListQuery = (inboxId: Ref<string>, messageId: Ref<string>) => {
  const enabled = computed(() => !!inboxId.value && !!messageId.value);

  return useQuery({
    queryKey: inboxQueryKeys.attachmentList(inboxId, messageId),
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerGetAttachments({
        id: inboxId.value,
        messageId: messageId.value,
      });
      return data;
    },
    enabled,
  });
};

export const useFetchInboxLabels = (inboxId: Ref<string>, refetch: Ref<boolean>) => {
  const enabled = computed(() => !!inboxId.value && refetch.value);

  return queryOptions({
    queryKey: ['inbox-labels', { inboxId: inboxId }] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerInboxLabels({
        id: inboxId.value,
      });
      return data;
    },
    enabled,
    refetchInterval: 10000,
  });
};

export const useFetchInboxIntentTags = (inboxId: Ref<string>) => {
  const enabled = computed(() => !!inboxId.value);

  return queryOptions({
    queryKey: ['inbox-intent-tags', { inboxId: inboxId }] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerInboxIntentTags({
        id: inboxId.value,
      });
      return data;
    },
    enabled,
    refetchInterval: 10000,
  });
};

export const useFetchUserSignature = (inboxId: Ref<string>, isEnabled: Ref<boolean>) => {
  const enabled = computed(() => !!inboxId.value && isEnabled.value);

  return queryOptions({
    queryKey: ['inbox-user-signature', { inboxId: inboxId }] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerUserSignature({
        id: inboxId.value,
      });
      return data;
    },
    enabled,
  });
};

export const useFetchC1ListQuery = () => {
  return queryOptions({
    queryKey: ['inbox-sync-status'] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerGetC1List();

      return data;
    },
  });
};

export const useFetchC1InboxListQuery = (c1MasterId: Ref<string>) => {
  const enabled = computed(() => !!c1MasterId.value);

  return queryOptions({
    queryKey: ['c1-inbox-list', { c1MasterId: c1MasterId }] as const,
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerGetInboxList({ id: c1MasterId.value });

      return data;
    },
    enabled,
  });
};

export const useFetchThreadMessageCountQuery = (inboxId: Ref<string>, threadIds: Ref<string[]>) => {
  const enabled = computed(() => !!inboxId.value && !!threadIds.value.length);

  return queryOptions({
    queryKey: ['thread-message-counts', { inboxId: inboxId, threadIds: threadIds }],
    queryFn: async (): Promise<Record<string, number>> => {
      const { data } = await inboxApi.inboxControllerThreadMessageCount({
        id: inboxId.value,
        threadIds: threadIds.value.join(','),
      });
      return data as Record<string, number>;
    },
    enabled,
  });
};

export const useFetchThreadLastEmotionsQuery = (inboxId: Ref<string>, threadIds: Ref<string[]>) => {
  const enabled = computed(() => !!inboxId.value && !!threadIds.value.length);

  return queryOptions({
    queryKey: ['thread-last-emotions', { inboxId: inboxId, threadIds: threadIds }],
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerThreadLastEmotions({
        id: inboxId.value,
        threadIds: threadIds.value.join(','),
      });
      return data;
    },
    enabled,
  });
};

export const useFetchThreadC2InfoQuery = (
  inboxId: Ref<string>,
  threadIds: Ref<string[]>,
  dateInfo: Ref<DateInfo | undefined>,
) => {
  const enabled = computed(() => !!inboxId.value && !!threadIds.value.length && !!dateInfo.value);

  return queryOptions({
    queryKey: ['thread-c2-info', { inboxId: inboxId, threadIds: threadIds }],
    queryFn: async () => {
      if (!dateInfo.value) throw new Error('DateInfo is required');

      const { data } = await inboxApi.inboxControllerThreadC2Info({
        id: inboxId.value,
        threadIds: threadIds.value.join(','),
        type: dateInfo.value.type,
        dateUtc: dateInfo.value.dateUtc,
        date: dateInfo.value.date,
      });

      return data;
    },
    enabled,
  });
};

export const useFetchCustomerEmails = (isAuthenticated: Ref<boolean>, c1Master: Ref<C1Master | undefined>) => {
  const enabled = computed(() => !!isAuthenticated.value && !!c1Master.value);

  return queryOptions({
    queryKey: ['customer-emails'],
    queryFn: async () => {
      const { data } = await inboxApi.inboxControllerCustomerEmails();
      return data;
    },
    enabled,
    refetchInterval: 3600000, // 1 hour
  });
};
