import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';
import { Layout } from '@/types/layout';

export const SurveyRoutes: RouteRecordRaw[] = [
  {
    path: '/surveys',
    name: RouteName.Surveys,
    meta: {
      title: 'Surveys',
      group: RouteName.Surveys,
      layout: Layout.Authenticated,
    },
    component: () => import('@/views/Surveys/SurveysView.vue'),
    redirect: { name: RouteName.NpsSurveys },
    children: [
      {
        path: 'nps',
        name: RouteName.NpsSurveys,
        meta: {
          title: 'NPS',
          group: RouteName.Surveys,
          layout: Layout.Authenticated,
        },
        component: () => import('@/views/Surveys/NpsSurveys.vue'),
        redirect: { name: RouteName.NpsSurveysOverview },
        children: [
          {
            path: 'overview',
            name: RouteName.NpsSurveysOverview,
            component: () => import('@/views/Surveys/NpsSurveysOverview.vue'),
            meta: {
              title: 'Nps Surveys Overview',
              group: RouteName.Surveys,
              layout: Layout.Authenticated,
            },
          },
          {
            path: 'details',
            name: RouteName.NpsSurveysDetails,
            component: () => import('@/views/Surveys/NpsSurveyDetails.vue'),
            redirect: { name: RouteName.NpsSurveysCustomerDetails },
            meta: {
              title: 'Nps Surveys Details',
              group: RouteName.Surveys,
              layout: Layout.Authenticated,
            },
            children: [
              {
                path: 'customers',
                name: RouteName.NpsSurveysCustomerDetails,
                component: () => import('@/views/Surveys/NpsSurveyCustomerDetails.vue'),
                meta: {
                  title: 'Customer Details',
                  group: RouteName.Surveys,
                  layout: Layout.Authenticated,
                },
              },
              {
                path: 'users',
                name: RouteName.NpsSurveysUserDetails,
                component: () => import('@/views/Surveys/NpsSurveyUserDetails.vue'),
                meta: {
                  title: 'User Details',
                  group: RouteName.Surveys,
                  layout: Layout.Authenticated,
                },
              },
            ],
          },
          {
            path: 'list',
            name: RouteName.NpsSurveysList,
            component: () => import('@/views/Surveys/NpsSurveysList.vue'),
            meta: {
              title: 'Nps Surveys',
              group: RouteName.Surveys,
              layout: Layout.Authenticated,
            },
          },
        ],
      },
      {
        path: 'csat',
        name: RouteName.CsatSurveys,
        meta: {
          title: 'CSAT Surveys',
          group: RouteName.Surveys,
          layout: Layout.Authenticated,
        },
        component: () => import('@/views/Surveys/CsatSurveys.vue'),
        redirect: { name: RouteName.CsatSurveysOverview },
        children: [
          {
            path: 'overview',
            name: RouteName.CsatSurveysOverview,
            component: () => import('@/views/Surveys/CsatSurveysOverview.vue'),
            meta: {
              title: 'Csat Surveys Overview',
              group: RouteName.Surveys,
              layout: Layout.Authenticated,
            },
          },
          {
            path: 'details',
            name: RouteName.CsatSurveysDetails,
            component: () => import('@/views/Surveys/CsatSurveyDetails.vue'),
            redirect: { name: RouteName.CsatSurveysCustomerDetails },
            meta: {
              title: 'Csat Surveys Details',
              group: RouteName.Surveys,
              layout: Layout.Authenticated,
            },
            children: [
              {
                path: 'customers',
                name: RouteName.CsatSurveysCustomerDetails,
                component: () => import('@/views/Surveys/CsatSurveyCustomerDetails.vue'),
                meta: {
                  title: 'Customer Details',
                  group: RouteName.Surveys,
                  layout: Layout.Authenticated,
                },
              },
              {
                path: 'users',
                name: RouteName.CsatSurveysUserDetails,
                component: () => import('@/views/Surveys/CsatSurveyUserDetails.vue'),
                meta: {
                  title: 'User Details',
                  group: RouteName.Surveys,
                  layout: Layout.Authenticated,
                },
              },
            ],
          },
          {
            path: 'list',
            name: RouteName.CsatSurveysList,
            component: () => import('@/views/Surveys/CsatSurveysList.vue'),
            meta: {
              title: 'Csat Surveys',
              group: RouteName.Surveys,
              layout: Layout.Authenticated,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/surveys/new',
    name: RouteName.NewSurvey,
    meta: {
      title: 'Surveys',
      group: RouteName.Surveys,
      layout: Layout.Authenticated,
    },
    component: () => import('@/views/Surveys/NewSurveyView.vue'),
  },
  {
    path: '/surveys/:surveyId/edit',
    name: RouteName.EditSurvey,
    meta: {
      title: 'Surveys',
      group: RouteName.Surveys,
      layout: Layout.Authenticated,
    },
    component: () => import('@/views/Surveys/EditSurveyView.vue'),
  },
  {
    path: '/:c1MasterId/surveys/:surveyId/response',
    name: RouteName.SurveyResponse,
    meta: {
      title: 'Surveys',
      layout: Layout.Scratch,
      skipAuth: true,
    },
    component: () => import('@/views/Surveys/SurveyResponseView.vue'),
  },
];

export const NpsSurveyDetailsRoute: RouteRecordRaw = {
  path: '/surveys/nps/list/:id',
  name: RouteName.NpsSurvey,
  meta: {
    group: RouteName.Surveys,
    layout: Layout.Authenticated,
  },
  component: () => import('../views/Surveys/SurveyDetails.vue'),
  children: [],
};

export const NpsSurveyStatisticsRoute: RouteRecordRaw = {
  path: '/surveys/nps/list/:id/:campaignRunId',
  name: RouteName.NpsSurveyStatistics,
  meta: {
    group: RouteName.Surveys,
    layout: Layout.Authenticated,
  },
  component: () => import('../views/Surveys/CampaignRunWiseSurveyStatistics.vue'),
  children: [],
};

export const CsatSurveyStatisticsRoute: RouteRecordRaw = {
  path: '/surveys/csat/list/:id/:campaignRunId',
  name: RouteName.CsatSurveyStatistics,
  meta: {
    group: RouteName.Surveys,
    layout: Layout.Authenticated,
  },
  component: () => import('../views/Surveys/CampaignRunWiseSurveyStatistics.vue'),
  children: [],
};

export const CsatSurveyDetailsRoute: RouteRecordRaw = {
  path: '/surveys/csat/list/:id',
  name: RouteName.CsatSurvey,
  meta: {
    group: RouteName.Surveys,
    layout: Layout.Authenticated,
  },
  component: () => import('../views/Surveys/SurveyDetails.vue'),
  children: [],
};
