/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CopySurveyDto } from '../model/';
// @ts-ignore
import { CreateSurveyDto } from '../model/';
// @ts-ignore
import { CreateSurveyResponseDto } from '../model/';
// @ts-ignore
import { PaginatedCustomerResponseDto } from '../model/';
// @ts-ignore
import { PaginatedSurveyLatestResponseDto } from '../model/';
// @ts-ignore
import { PaginatedSurveyResponseDto } from '../model/';
// @ts-ignore
import { Survey } from '../model/';
// @ts-ignore
import { SurveyQuestion } from '../model/';
// @ts-ignore
import { SurveyResponseOverviewDto } from '../model/';
// @ts-ignore
import { SurveyResponseOverviewFilterDto } from '../model/';
// @ts-ignore
import { SurveyRunPaginationDto } from '../model/';
// @ts-ignore
import { SurveysFilterDto } from '../model/';
// @ts-ignore
import { SurveysPaginationDto } from '../model/';
// @ts-ignore
import { SurveysResponseFilterDto } from '../model/';
// @ts-ignore
import { U2SurveyResponse } from '../model/';
// @ts-ignore
import { UpdateSurveyFlagDto } from '../model/';
// @ts-ignore
import { UserSurveyResponseDto } from '../model/';
/**
 * SurveysApi - axios parameter creator
 * @export
 */
export const SurveysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy survey from another survey by id
         * @param {string} id 
         * @param {CopySurveyDto} copySurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCopySurvey: async (id: string, copySurveyDto: CopySurveyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveysControllerCopySurvey', 'id', id)
            // verify required parameter 'copySurveyDto' is not null or undefined
            assertParamExists('surveysControllerCopySurvey', 'copySurveyDto', copySurveyDto)
            const localVarPath = `/api/surveys/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copySurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCreate: async (createSurveyDto: CreateSurveyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSurveyDto' is not null or undefined
            assertParamExists('surveysControllerCreate', 'createSurveyDto', createSurveyDto)
            const localVarPath = `/api/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete survey by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerDeleteSurvey: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveysControllerDeleteSurvey', 'id', id)
            const localVarPath = `/api/surveys/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all responses of an user for a survey type
         * @param {string} surveyType 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllResponsesOfUSerForSurveyType: async (surveyType: string, u2Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyType' is not null or undefined
            assertParamExists('surveysControllerFindAllResponsesOfUSerForSurveyType', 'surveyType', surveyType)
            // verify required parameter 'u2Id' is not null or undefined
            assertParamExists('surveysControllerFindAllResponsesOfUSerForSurveyType', 'u2Id', u2Id)
            const localVarPath = `/api/surveys/survey-response/all/{surveyType}/{u2Id}`
                .replace(`{${"surveyType"}}`, encodeURIComponent(String(surveyType)))
                .replace(`{${"u2Id"}}`, encodeURIComponent(String(u2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all user responses for a survey
         * @param {string} surveyId 
         * @param {string} campaignRunId 
         * @param {SurveysResponseFilterDto} surveysResponseFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllUserResponseForSurvey: async (surveyId: string, campaignRunId: string, surveysResponseFilterDto: SurveysResponseFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindAllUserResponseForSurvey', 'surveyId', surveyId)
            // verify required parameter 'campaignRunId' is not null or undefined
            assertParamExists('surveysControllerFindAllUserResponseForSurvey', 'campaignRunId', campaignRunId)
            // verify required parameter 'surveysResponseFilterDto' is not null or undefined
            assertParamExists('surveysControllerFindAllUserResponseForSurvey', 'surveysResponseFilterDto', surveysResponseFilterDto)
            const localVarPath = `/api/surveys/find-all-u2-response/{surveyId}/{campaignRunId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"campaignRunId"}}`, encodeURIComponent(String(campaignRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveysResponseFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch paginated list of surveys along with survey responses
         * @param {SurveysFilterDto} surveysFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllWithPagination: async (surveysFilterDto: SurveysFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveysFilterDto' is not null or undefined
            assertParamExists('surveysControllerFindAllWithPagination', 'surveysFilterDto', surveysFilterDto)
            const localVarPath = `/api/surveys/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveysFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the sent campaign mails against a survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindCampaignRunWiseSurveyStatistics: async (surveyId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindCampaignRunWiseSurveyStatistics', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/get-survey-stats/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find survey response overview for all csat surveys according to date selection
         * @param {SurveyResponseOverviewFilterDto} surveyResponseOverviewFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindCsatSurveyResponseOverview: async (surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyResponseOverviewFilterDto' is not null or undefined
            assertParamExists('surveysControllerFindCsatSurveyResponseOverview', 'surveyResponseOverviewFilterDto', surveyResponseOverviewFilterDto)
            const localVarPath = `/api/surveys/overview/csat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyResponseOverviewFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find latest responders for survey user details list
         * @param {string} surveyType 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindLatestResponders: async (surveyType: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyType' is not null or undefined
            assertParamExists('surveysControllerFindLatestResponders', 'surveyType', surveyType)
            const localVarPath = `/api/surveys/survey-response/latest/{surveyType}`
                .replace(`{${"surveyType"}}`, encodeURIComponent(String(surveyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find customer wise survey details against a survey type
         * @param {string} surveyType 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindLatestSurveyDetailsForCustomers: async (surveyType: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyType' is not null or undefined
            assertParamExists('surveysControllerFindLatestSurveyDetailsForCustomers', 'surveyType', surveyType)
            const localVarPath = `/api/surveys/latest-response/customers/{surveyType}`
                .replace(`{${"surveyType"}}`, encodeURIComponent(String(surveyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find survey response overview for all nps surveys according to date selection
         * @param {SurveyResponseOverviewFilterDto} surveyResponseOverviewFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindNpsSurveyResponseOverview: async (surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyResponseOverviewFilterDto' is not null or undefined
            assertParamExists('surveysControllerFindNpsSurveyResponseOverview', 'surveyResponseOverviewFilterDto', surveyResponseOverviewFilterDto)
            const localVarPath = `/api/surveys/overview/nps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyResponseOverviewFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find survey by id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindOne: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindOne', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find survey by survey id and tenant id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindOneById: async (tenantId: string, surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerFindOneById', 'tenantId', tenantId)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindOneById', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/survey/{tenantId}/{surveyId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyQuestions: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyQuestions', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/questions`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if u2 survey response exists by u2_id, survey_id, campaign_run_unique_id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} u2Id 
         * @param {string} campaignRunUniqueId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponseForU2: async (tenantId: string, surveyId: string, u2Id: string, campaignRunUniqueId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponseForU2', 'tenantId', tenantId)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponseForU2', 'surveyId', surveyId)
            // verify required parameter 'u2Id' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponseForU2', 'u2Id', u2Id)
            // verify required parameter 'campaignRunUniqueId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponseForU2', 'campaignRunUniqueId', campaignRunUniqueId)
            const localVarPath = `/api/surveys/survey-response/{tenantId}/{surveyId}/{u2Id}/{campaignRunUniqueId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"u2Id"}}`, encodeURIComponent(String(u2Id)))
                .replace(`{${"campaignRunUniqueId"}}`, encodeURIComponent(String(campaignRunUniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch survey responses by survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponses: async (surveyId: string, xTenantId?: string, campaignRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponses', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/responses/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignRunId !== undefined) {
                localVarQueryParameter['campaignRunId'] = campaignRunId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique responders count, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindUniqueResponders: async (xTenantId?: string, surveyId?: string, campaignRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/surveys/unique/responders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['surveyId'] = surveyId;
            }

            if (campaignRunId !== undefined) {
                localVarQueryParameter['campaignRunId'] = campaignRunId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPending: async (tenantId: string, u2Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerPending', 'tenantId', tenantId)
            // verify required parameter 'u2Id' is not null or undefined
            assertParamExists('surveysControllerPending', 'u2Id', u2Id)
            const localVarPath = `/api/surveys/pending/{tenantId}/{u2Id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"u2Id"}}`, encodeURIComponent(String(u2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPublish: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerPublish', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/publish`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find questions of survey by survey id and and tenant id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerQuestions: async (tenantId: string, surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerQuestions', 'tenantId', tenantId)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerQuestions', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/questions/{tenantId}/{surveyId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user response by tenant id and response dto
         * @param {string} tenantId 
         * @param {CreateSurveyResponseDto} createSurveyResponseDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerRespond: async (tenantId: string, createSurveyResponseDto: CreateSurveyResponseDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerRespond', 'tenantId', tenantId)
            // verify required parameter 'createSurveyResponseDto' is not null or undefined
            assertParamExists('surveysControllerRespond', 'createSurveyResponseDto', createSurveyResponseDto)
            const localVarPath = `/api/surveys/respond/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyResponseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send survey preview email
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerSendSurveyEmail: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerSendSurveyEmail', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/send`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle survey on_off_flag by id
         * @param {string} id 
         * @param {UpdateSurveyFlagDto} updateSurveyFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerToggleSurvey: async (id: string, updateSurveyFlagDto: UpdateSurveyFlagDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveysControllerToggleSurvey', 'id', id)
            // verify required parameter 'updateSurveyFlagDto' is not null or undefined
            assertParamExists('surveysControllerToggleSurvey', 'updateSurveyFlagDto', updateSurveyFlagDto)
            const localVarPath = `/api/surveys/{id}/toggle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSurveyFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUnpublish: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerUnpublish', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/unpublish`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUpdate: async (surveyId: string, createSurveyDto: CreateSurveyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerUpdate', 'surveyId', surveyId)
            // verify required parameter 'createSurveyDto' is not null or undefined
            assertParamExists('surveysControllerUpdate', 'createSurveyDto', createSurveyDto)
            const localVarPath = `/api/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveysApi - functional programming interface
 * @export
 */
export const SurveysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy survey from another survey by id
         * @param {string} id 
         * @param {CopySurveyDto} copySurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerCopySurvey(id: string, copySurveyDto: CopySurveyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerCopySurvey(id, copySurveyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerCreate(createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerCreate(createSurveyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete survey by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerDeleteSurvey(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerDeleteSurvey(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Survey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all responses of an user for a survey type
         * @param {string} surveyType 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindAllResponsesOfUSerForSurveyType(surveyType: string, u2Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindAllResponsesOfUSerForSurveyType(surveyType, u2Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all user responses for a survey
         * @param {string} surveyId 
         * @param {string} campaignRunId 
         * @param {SurveysResponseFilterDto} surveysResponseFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindAllUserResponseForSurvey(surveyId: string, campaignRunId: string, surveysResponseFilterDto: SurveysResponseFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindAllUserResponseForSurvey(surveyId, campaignRunId, surveysResponseFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch paginated list of surveys along with survey responses
         * @param {SurveysFilterDto} surveysFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindAllWithPagination(surveysFilterDto: SurveysFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveysPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindAllWithPagination(surveysFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the sent campaign mails against a survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindCampaignRunWiseSurveyStatistics(surveyId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyRunPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindCampaignRunWiseSurveyStatistics(surveyId, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find survey response overview for all csat surveys according to date selection
         * @param {SurveyResponseOverviewFilterDto} surveyResponseOverviewFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindCsatSurveyResponseOverview(surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyResponseOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindCsatSurveyResponseOverview(surveyResponseOverviewFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find latest responders for survey user details list
         * @param {string} surveyType 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindLatestResponders(surveyType: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSurveyLatestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindLatestResponders(surveyType, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find customer wise survey details against a survey type
         * @param {string} surveyType 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindLatestSurveyDetailsForCustomers(surveyType: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindLatestSurveyDetailsForCustomers(surveyType, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find survey response overview for all nps surveys according to date selection
         * @param {SurveyResponseOverviewFilterDto} surveyResponseOverviewFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindNpsSurveyResponseOverview(surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyResponseOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindNpsSurveyResponseOverview(surveyResponseOverviewFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find survey by id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindOne(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindOne(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find survey by survey id and tenant id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindOneById(tenantId: string, surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindOneById(tenantId, surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindSurveyQuestions(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindSurveyQuestions(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if u2 survey response exists by u2_id, survey_id, campaign_run_unique_id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} u2Id 
         * @param {string} campaignRunUniqueId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindSurveyResponseForU2(tenantId: string, surveyId: string, u2Id: string, campaignRunUniqueId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2SurveyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindSurveyResponseForU2(tenantId, surveyId, u2Id, campaignRunUniqueId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch survey responses by survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindSurveyResponses(surveyId: string, xTenantId?: string, campaignRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2SurveyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindSurveyResponses(surveyId, xTenantId, campaignRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique responders count, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindUniqueResponders(xTenantId?: string, surveyId?: string, campaignRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindUniqueResponders(xTenantId, surveyId, campaignRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerPending(tenantId: string, u2Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerPending(tenantId, u2Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerPublish(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerPublish(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find questions of survey by survey id and and tenant id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerQuestions(tenantId: string, surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerQuestions(tenantId, surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add user response by tenant id and response dto
         * @param {string} tenantId 
         * @param {CreateSurveyResponseDto} createSurveyResponseDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerRespond(tenantId: string, createSurveyResponseDto: CreateSurveyResponseDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2SurveyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerRespond(tenantId, createSurveyResponseDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send survey preview email
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerSendSurveyEmail(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerSendSurveyEmail(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle survey on_off_flag by id
         * @param {string} id 
         * @param {UpdateSurveyFlagDto} updateSurveyFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerToggleSurvey(id: string, updateSurveyFlagDto: UpdateSurveyFlagDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerToggleSurvey(id, updateSurveyFlagDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerUnpublish(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerUnpublish(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerUpdate(surveyId: string, createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerUpdate(surveyId, createSurveyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveysApi - factory interface
 * @export
 */
export const SurveysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveysApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy survey from another survey by id
         * @param {string} id 
         * @param {CopySurveyDto} copySurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCopySurvey(id: string, copySurveyDto: CopySurveyDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerCopySurvey(id, copySurveyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCreate(createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerCreate(createSurveyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete survey by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerDeleteSurvey(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveysControllerDeleteSurvey(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Survey>> {
            return localVarFp.surveysControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all responses of an user for a survey type
         * @param {string} surveyType 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllResponsesOfUSerForSurveyType(surveyType: string, u2Id: string, xTenantId?: string, options?: any): AxiosPromise<UserSurveyResponseDto> {
            return localVarFp.surveysControllerFindAllResponsesOfUSerForSurveyType(surveyType, u2Id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all user responses for a survey
         * @param {string} surveyId 
         * @param {string} campaignRunId 
         * @param {SurveysResponseFilterDto} surveysResponseFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllUserResponseForSurvey(surveyId: string, campaignRunId: string, surveysResponseFilterDto: SurveysResponseFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedSurveyResponseDto> {
            return localVarFp.surveysControllerFindAllUserResponseForSurvey(surveyId, campaignRunId, surveysResponseFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch paginated list of surveys along with survey responses
         * @param {SurveysFilterDto} surveysFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllWithPagination(surveysFilterDto: SurveysFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<SurveysPaginationDto> {
            return localVarFp.surveysControllerFindAllWithPagination(surveysFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the sent campaign mails against a survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindCampaignRunWiseSurveyStatistics(surveyId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<SurveyRunPaginationDto> {
            return localVarFp.surveysControllerFindCampaignRunWiseSurveyStatistics(surveyId, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find survey response overview for all csat surveys according to date selection
         * @param {SurveyResponseOverviewFilterDto} surveyResponseOverviewFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindCsatSurveyResponseOverview(surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyResponseOverviewDto>> {
            return localVarFp.surveysControllerFindCsatSurveyResponseOverview(surveyResponseOverviewFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find latest responders for survey user details list
         * @param {string} surveyType 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindLatestResponders(surveyType: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedSurveyLatestResponseDto> {
            return localVarFp.surveysControllerFindLatestResponders(surveyType, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find customer wise survey details against a survey type
         * @param {string} surveyType 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindLatestSurveyDetailsForCustomers(surveyType: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedCustomerResponseDto> {
            return localVarFp.surveysControllerFindLatestSurveyDetailsForCustomers(surveyType, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find survey response overview for all nps surveys according to date selection
         * @param {SurveyResponseOverviewFilterDto} surveyResponseOverviewFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindNpsSurveyResponseOverview(surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyResponseOverviewDto>> {
            return localVarFp.surveysControllerFindNpsSurveyResponseOverview(surveyResponseOverviewFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find survey by id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindOne(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerFindOne(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find survey by survey id and tenant id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindOneById(tenantId: string, surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerFindOneById(tenantId, surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyQuestions(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyQuestion>> {
            return localVarFp.surveysControllerFindSurveyQuestions(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if u2 survey response exists by u2_id, survey_id, campaign_run_unique_id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} u2Id 
         * @param {string} campaignRunUniqueId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponseForU2(tenantId: string, surveyId: string, u2Id: string, campaignRunUniqueId: string, xTenantId?: string, options?: any): AxiosPromise<Array<U2SurveyResponse>> {
            return localVarFp.surveysControllerFindSurveyResponseForU2(tenantId, surveyId, u2Id, campaignRunUniqueId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch survey responses by survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponses(surveyId: string, xTenantId?: string, campaignRunId?: string, options?: any): AxiosPromise<Array<U2SurveyResponse>> {
            return localVarFp.surveysControllerFindSurveyResponses(surveyId, xTenantId, campaignRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique responders count, with optional survey id and campaign run id fields
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {string} [campaignRunId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindUniqueResponders(xTenantId?: string, surveyId?: string, campaignRunId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.surveysControllerFindUniqueResponders(xTenantId, surveyId, campaignRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPending(tenantId: string, u2Id: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerPending(tenantId, u2Id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPublish(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerPublish(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find questions of survey by survey id and and tenant id
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerQuestions(tenantId: string, surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyQuestion>> {
            return localVarFp.surveysControllerQuestions(tenantId, surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add user response by tenant id and response dto
         * @param {string} tenantId 
         * @param {CreateSurveyResponseDto} createSurveyResponseDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerRespond(tenantId: string, createSurveyResponseDto: CreateSurveyResponseDto, xTenantId?: string, options?: any): AxiosPromise<Array<U2SurveyResponse>> {
            return localVarFp.surveysControllerRespond(tenantId, createSurveyResponseDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send survey preview email
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerSendSurveyEmail(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.surveysControllerSendSurveyEmail(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle survey on_off_flag by id
         * @param {string} id 
         * @param {UpdateSurveyFlagDto} updateSurveyFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerToggleSurvey(id: string, updateSurveyFlagDto: UpdateSurveyFlagDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerToggleSurvey(id, updateSurveyFlagDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUnpublish(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerUnpublish(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUpdate(surveyId: string, createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerUpdate(surveyId, createSurveyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for surveysControllerCopySurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerCopySurveyRequest
 */
export interface SurveysApiSurveysControllerCopySurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerCopySurvey
     */
    readonly id: string

    /**
     * 
     * @type {CopySurveyDto}
     * @memberof SurveysApiSurveysControllerCopySurvey
     */
    readonly copySurveyDto: CopySurveyDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerCopySurvey
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerCreate operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerCreateRequest
 */
export interface SurveysApiSurveysControllerCreateRequest {
    /**
     * 
     * @type {CreateSurveyDto}
     * @memberof SurveysApiSurveysControllerCreate
     */
    readonly createSurveyDto: CreateSurveyDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerDeleteSurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerDeleteSurveyRequest
 */
export interface SurveysApiSurveysControllerDeleteSurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerDeleteSurvey
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerDeleteSurvey
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindAll operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindAllRequest
 */
export interface SurveysApiSurveysControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindAllResponsesOfUSerForSurveyType operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyTypeRequest
 */
export interface SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyType
     */
    readonly surveyType: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyType
     */
    readonly u2Id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyType
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindAllUserResponseForSurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindAllUserResponseForSurveyRequest
 */
export interface SurveysApiSurveysControllerFindAllUserResponseForSurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly campaignRunId: string

    /**
     * 
     * @type {SurveysResponseFilterDto}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly surveysResponseFilterDto: SurveysResponseFilterDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllUserResponseForSurvey
     */
    readonly after?: string
}

/**
 * Request parameters for surveysControllerFindAllWithPagination operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindAllWithPaginationRequest
 */
export interface SurveysApiSurveysControllerFindAllWithPaginationRequest {
    /**
     * 
     * @type {SurveysFilterDto}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly surveysFilterDto: SurveysFilterDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly after?: string
}

/**
 * Request parameters for surveysControllerFindCampaignRunWiseSurveyStatistics operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatisticsRequest
 */
export interface SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatisticsRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatistics
     */
    readonly after?: string
}

/**
 * Request parameters for surveysControllerFindCsatSurveyResponseOverview operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindCsatSurveyResponseOverviewRequest
 */
export interface SurveysApiSurveysControllerFindCsatSurveyResponseOverviewRequest {
    /**
     * 
     * @type {SurveyResponseOverviewFilterDto}
     * @memberof SurveysApiSurveysControllerFindCsatSurveyResponseOverview
     */
    readonly surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindCsatSurveyResponseOverview
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindLatestResponders operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindLatestRespondersRequest
 */
export interface SurveysApiSurveysControllerFindLatestRespondersRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly surveyType: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestResponders
     */
    readonly after?: string
}

/**
 * Request parameters for surveysControllerFindLatestSurveyDetailsForCustomers operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomersRequest
 */
export interface SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomersRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly surveyType: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomers
     */
    readonly after?: string
}

/**
 * Request parameters for surveysControllerFindNpsSurveyResponseOverview operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindNpsSurveyResponseOverviewRequest
 */
export interface SurveysApiSurveysControllerFindNpsSurveyResponseOverviewRequest {
    /**
     * 
     * @type {SurveyResponseOverviewFilterDto}
     * @memberof SurveysApiSurveysControllerFindNpsSurveyResponseOverview
     */
    readonly surveyResponseOverviewFilterDto: SurveyResponseOverviewFilterDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindNpsSurveyResponseOverview
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindOne operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindOneRequest
 */
export interface SurveysApiSurveysControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOne
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindOneById operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindOneByIdRequest
 */
export interface SurveysApiSurveysControllerFindOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOneById
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOneById
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOneById
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindSurveyQuestions operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindSurveyQuestionsRequest
 */
export interface SurveysApiSurveysControllerFindSurveyQuestionsRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyQuestions
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyQuestions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindSurveyResponseForU2 operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindSurveyResponseForU2Request
 */
export interface SurveysApiSurveysControllerFindSurveyResponseForU2Request {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseForU2
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseForU2
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseForU2
     */
    readonly u2Id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseForU2
     */
    readonly campaignRunUniqueId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseForU2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindSurveyResponses operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindSurveyResponsesRequest
 */
export interface SurveysApiSurveysControllerFindSurveyResponsesRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponses
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponses
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponses
     */
    readonly campaignRunId?: string
}

/**
 * Request parameters for surveysControllerFindUniqueResponders operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindUniqueRespondersRequest
 */
export interface SurveysApiSurveysControllerFindUniqueRespondersRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindUniqueResponders
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindUniqueResponders
     */
    readonly surveyId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindUniqueResponders
     */
    readonly campaignRunId?: string
}

/**
 * Request parameters for surveysControllerPending operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerPendingRequest
 */
export interface SurveysApiSurveysControllerPendingRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPending
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPending
     */
    readonly u2Id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPending
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerPublish operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerPublishRequest
 */
export interface SurveysApiSurveysControllerPublishRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPublish
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPublish
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerQuestions operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerQuestionsRequest
 */
export interface SurveysApiSurveysControllerQuestionsRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerQuestions
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerQuestions
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerQuestions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerRespond operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerRespondRequest
 */
export interface SurveysApiSurveysControllerRespondRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateSurveyResponseDto}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly createSurveyResponseDto: CreateSurveyResponseDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerSendSurveyEmail operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerSendSurveyEmailRequest
 */
export interface SurveysApiSurveysControllerSendSurveyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerSendSurveyEmail
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerSendSurveyEmail
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerToggleSurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerToggleSurveyRequest
 */
export interface SurveysApiSurveysControllerToggleSurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerToggleSurvey
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSurveyFlagDto}
     * @memberof SurveysApiSurveysControllerToggleSurvey
     */
    readonly updateSurveyFlagDto: UpdateSurveyFlagDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerToggleSurvey
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerUnpublish operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerUnpublishRequest
 */
export interface SurveysApiSurveysControllerUnpublishRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUnpublish
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUnpublish
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerUpdate operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerUpdateRequest
 */
export interface SurveysApiSurveysControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUpdate
     */
    readonly surveyId: string

    /**
     * 
     * @type {CreateSurveyDto}
     * @memberof SurveysApiSurveysControllerUpdate
     */
    readonly createSurveyDto: CreateSurveyDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * SurveysApi - object-oriented interface
 * @export
 * @class SurveysApi
 * @extends {BaseAPI}
 */
export class SurveysApi extends BaseAPI {
    /**
     * 
     * @summary Copy survey from another survey by id
     * @param {SurveysApiSurveysControllerCopySurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerCopySurvey(requestParameters: SurveysApiSurveysControllerCopySurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerCopySurvey(requestParameters.id, requestParameters.copySurveyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerCreate(requestParameters: SurveysApiSurveysControllerCreateRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerCreate(requestParameters.createSurveyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete survey by id
     * @param {SurveysApiSurveysControllerDeleteSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerDeleteSurvey(requestParameters: SurveysApiSurveysControllerDeleteSurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerDeleteSurvey(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindAll(requestParameters: SurveysApiSurveysControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all responses of an user for a survey type
     * @param {SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindAllResponsesOfUSerForSurveyType(requestParameters: SurveysApiSurveysControllerFindAllResponsesOfUSerForSurveyTypeRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindAllResponsesOfUSerForSurveyType(requestParameters.surveyType, requestParameters.u2Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all user responses for a survey
     * @param {SurveysApiSurveysControllerFindAllUserResponseForSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindAllUserResponseForSurvey(requestParameters: SurveysApiSurveysControllerFindAllUserResponseForSurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindAllUserResponseForSurvey(requestParameters.surveyId, requestParameters.campaignRunId, requestParameters.surveysResponseFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch paginated list of surveys along with survey responses
     * @param {SurveysApiSurveysControllerFindAllWithPaginationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindAllWithPagination(requestParameters: SurveysApiSurveysControllerFindAllWithPaginationRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindAllWithPagination(requestParameters.surveysFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the sent campaign mails against a survey id
     * @param {SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindCampaignRunWiseSurveyStatistics(requestParameters: SurveysApiSurveysControllerFindCampaignRunWiseSurveyStatisticsRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindCampaignRunWiseSurveyStatistics(requestParameters.surveyId, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find survey response overview for all csat surveys according to date selection
     * @param {SurveysApiSurveysControllerFindCsatSurveyResponseOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindCsatSurveyResponseOverview(requestParameters: SurveysApiSurveysControllerFindCsatSurveyResponseOverviewRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindCsatSurveyResponseOverview(requestParameters.surveyResponseOverviewFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find latest responders for survey user details list
     * @param {SurveysApiSurveysControllerFindLatestRespondersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindLatestResponders(requestParameters: SurveysApiSurveysControllerFindLatestRespondersRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindLatestResponders(requestParameters.surveyType, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find customer wise survey details against a survey type
     * @param {SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindLatestSurveyDetailsForCustomers(requestParameters: SurveysApiSurveysControllerFindLatestSurveyDetailsForCustomersRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindLatestSurveyDetailsForCustomers(requestParameters.surveyType, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find survey response overview for all nps surveys according to date selection
     * @param {SurveysApiSurveysControllerFindNpsSurveyResponseOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindNpsSurveyResponseOverview(requestParameters: SurveysApiSurveysControllerFindNpsSurveyResponseOverviewRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindNpsSurveyResponseOverview(requestParameters.surveyResponseOverviewFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find survey by id
     * @param {SurveysApiSurveysControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindOne(requestParameters: SurveysApiSurveysControllerFindOneRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindOne(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find survey by survey id and tenant id
     * @param {SurveysApiSurveysControllerFindOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindOneById(requestParameters: SurveysApiSurveysControllerFindOneByIdRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindOneById(requestParameters.tenantId, requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerFindSurveyQuestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindSurveyQuestions(requestParameters: SurveysApiSurveysControllerFindSurveyQuestionsRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindSurveyQuestions(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if u2 survey response exists by u2_id, survey_id, campaign_run_unique_id
     * @param {SurveysApiSurveysControllerFindSurveyResponseForU2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindSurveyResponseForU2(requestParameters: SurveysApiSurveysControllerFindSurveyResponseForU2Request, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindSurveyResponseForU2(requestParameters.tenantId, requestParameters.surveyId, requestParameters.u2Id, requestParameters.campaignRunUniqueId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch survey responses by survey id
     * @param {SurveysApiSurveysControllerFindSurveyResponsesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindSurveyResponses(requestParameters: SurveysApiSurveysControllerFindSurveyResponsesRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindSurveyResponses(requestParameters.surveyId, requestParameters.xTenantId, requestParameters.campaignRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique responders count, with optional survey id and campaign run id fields
     * @param {SurveysApiSurveysControllerFindUniqueRespondersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindUniqueResponders(requestParameters: SurveysApiSurveysControllerFindUniqueRespondersRequest = {}, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindUniqueResponders(requestParameters.xTenantId, requestParameters.surveyId, requestParameters.campaignRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerPendingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerPending(requestParameters: SurveysApiSurveysControllerPendingRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerPending(requestParameters.tenantId, requestParameters.u2Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerPublishRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerPublish(requestParameters: SurveysApiSurveysControllerPublishRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerPublish(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find questions of survey by survey id and and tenant id
     * @param {SurveysApiSurveysControllerQuestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerQuestions(requestParameters: SurveysApiSurveysControllerQuestionsRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerQuestions(requestParameters.tenantId, requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add user response by tenant id and response dto
     * @param {SurveysApiSurveysControllerRespondRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerRespond(requestParameters: SurveysApiSurveysControllerRespondRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerRespond(requestParameters.tenantId, requestParameters.createSurveyResponseDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send survey preview email
     * @param {SurveysApiSurveysControllerSendSurveyEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerSendSurveyEmail(requestParameters: SurveysApiSurveysControllerSendSurveyEmailRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerSendSurveyEmail(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle survey on_off_flag by id
     * @param {SurveysApiSurveysControllerToggleSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerToggleSurvey(requestParameters: SurveysApiSurveysControllerToggleSurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerToggleSurvey(requestParameters.id, requestParameters.updateSurveyFlagDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerUnpublishRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerUnpublish(requestParameters: SurveysApiSurveysControllerUnpublishRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerUnpublish(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerUpdate(requestParameters: SurveysApiSurveysControllerUpdateRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerUpdate(requestParameters.surveyId, requestParameters.createSurveyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
