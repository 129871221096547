import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { CustomKpiRoutes } from './custom-kpi-routes';
import { RouteName } from './route-name';

export const OnboardingRoutes: RouteRecordRaw = {
  path: '/onboarding',
  component: () => import('../views/Onboarding.vue'),
  name: RouteName.Onboarding,
  redirect: { name: RouteName.Profile },
  meta: {
    layout: Layout.Onboarding,
  },
  children: [
    {
      path: 'profile',
      name: RouteName.Profile,
      component: () => import('@/views/Onboarding/Profile.vue'),
      meta: {
        title: 'Your Profile',
      },
    },
    {
      path: 'business-info',
      name: RouteName.BusinessInfo,
      component: () => import('@/views/Onboarding/BusinessMetaData.vue'),
      meta: {
        title: 'Business Info',
      },
    },
    {
      path: 'zapscale-users',
      component: () => import('@/views/Onboarding/ZapscaleUsersClassic.vue'),
      children: [
        {
          path: '',
          name: RouteName.ZapscaleUsers,
          component: () => import('@/views/Onboarding/ZapscaleUsers/Overview.vue'),
          meta: {
            title: 'Zapscale Users - Overview',
          },
        },
        {
          path: ':category',
          name: RouteName.ZapscaleUserList,
          component: () => import('@/views/Onboarding/ZapscaleUsers/UserList.vue'),
          props: true,
          meta: {
            title: 'Zapscale Users - Invite',
          },
          children: [
            {
              path: 'new-invitation',
              name: RouteName.ZapscaleNewInvitation,
              component: () => import('@/views/Onboarding/ZapscaleUsers/NewInvitation.vue'),
              props: true,
              meta: {
                title: 'Zapscale Users - Invite',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'saas-app',
      component: () => import('@/views/Onboarding/SaasApp.vue'),
      children: [
        {
          path: '',
          name: RouteName.SaasApp,
          component: () => import('@/views/Onboarding/SaasAppOverview.vue'),
          meta: {
            title: 'Saas App - Overview',
          },
        },
        {
          path: 'site-map',
          name: RouteName.SaasAppSiteMap,
          component: () => import('@/views/Onboarding/SaasAppSiteMap.vue'),
          meta: {
            title: 'Saas App - SiteMap',
          },
        },
        {
          path: 'products',
          name: RouteName.SaasAppProducts,
          component: () => import('@/views/Onboarding/SaasAppProducts/SaasAppProductList.vue'),
          meta: {
            title: 'Saas App - Products',
          },
          children: [
            {
              path: 'new',
              name: RouteName.SaasAppNewProduct,
              component: () => import('@/views/Onboarding/SaasAppProducts/SaasAppNewProduct.vue'),
              props: true,
              meta: {
                title: 'Saas App - New Product',
              },
            },
            {
              path: ':id/edit',
              name: RouteName.SaasAppEditProduct,
              component: () => import('@/views/Onboarding/SaasAppProducts/SaasAppEditProduct.vue'),
              props: true,
              meta: {
                title: 'Saas App - Edit Product',
              },
            },
          ],
        },
        {
          path: 'tracker-code',
          name: RouteName.SaasAppTrackerCode,
          component: () => import('@/views/Onboarding/SaasAppTrackerCode.vue'),
          meta: {
            title: 'Saas App - Application Pages',
          },
        },
        {
          path: 'events',
          name: RouteName.SaasAppEvents,
          component: () => import('@/views/Onboarding/SaasAppEvents/SaasAppEventList.vue'),
          meta: {
            title: 'Saas App - Features',
          },
        },
        {
          path: 'user-object',
          name: RouteName.SaasAppUserObject,
          component: () => import('@/views/Onboarding/SaasAppUserObject.vue'),
          meta: {
            title: 'Saas App - User Object',
          },
        },
        {
          path: 'customers',
          name: RouteName.SaasAppCustomers,
          component: () => import('@/views/Onboarding/SaasAppCustomers.vue'),
          meta: {
            title: 'Add Customers',
          },
        },
        {
          path: 'users',
          name: RouteName.SaasAppUsers,
          component: () => import('@/views/Onboarding/SaasAppUsers.vue'),
          meta: {
            title: 'Add Users',
          },
        },
        {
          path: 'usage-and-outcome-kpis',
          name: RouteName.UsageAndOutcomeKpis,
          component: () => import('@/views/Onboarding/UsageAndOutcomeKpi.vue'),
          meta: {
            title: 'Saas App - User Object',
          },
          children: [{ ...CustomKpiRoutes, path: 'new-usage-kpi', name: RouteName.NewUsageKpi }],
        },
        {
          path: 'cs-gutfeel-kpis',
          name: RouteName.CsHandfeelKpis,
          component: () => import('@/views/Onboarding/CsHandfeelKpi.vue'),
          meta: {
            title: 'Saas App - Cs Gutfeel',
          },
        },
      ],
    },
    {
      path: 'peripheral-apps',
      component: () => import('@/views/Onboarding/PeripheralApps.vue'),
      children: [
        {
          path: '',
          name: RouteName.PeripheralApps,
          component: () => import('@/views/Onboarding/PeripheralAppsOverview.vue'),
          meta: {
            title: 'Peripheral Apps - Overview',
          },
        },
        {
          path: 'crm',
          name: RouteName.PeripheralAppsCrm,
          component: () => import('@/views/Onboarding/PeripheralAppsCrm.vue'),
          meta: {
            title: 'Peripheral Apps - CRM',
          },
        },
        {
          path: 'ticketing',
          name: RouteName.PeripheralAppsTicketing,
          component: () => import('@/views/Onboarding/PeripheralAppsBugTracking.vue'),
          meta: {
            title: 'Peripheral Apps - Bug Tracking',
          },
        },
        // {
        //   path: 'email-system',
        //   name: RouteName.PeripheralAppsEmailSystem,
        //   component: () => import('@/views/Onboarding/PeripheralAppsEmailSystem.vue'),
        //   meta: {
        //     title: 'Peripheral Apps - Email System',
        //   },
        // },
        {
          path: 'feature-requests',
          name: RouteName.PeripheralAppsFeatureRequests,
          component: () => import('@/views/Onboarding/PeripheralAppsFeatureRequests.vue'),
          meta: {
            title: 'Peripheral Apps - Feature Requests',
          },
        },
        {
          path: 'payment-system',
          name: RouteName.PeripheralAppsPaymentSystem,
          component: () => import('@/views/Onboarding/PeripheralAppsPaymentSystem.vue'),
          meta: {
            title: 'Peripheral Apps - Payment System',
          },
        },
        {
          path: 'comm-system',
          name: RouteName.PeripheralAppsCommSystem,
          component: () => import('@/views/Onboarding/PeripheralAppsCommSystem.vue'),
          meta: {
            title: 'Peripheral Apps - Comm System',
          },
        },
        {
          path: 'product-usage',
          name: RouteName.PeripheralAppsProductUsage,
          component: () => import('@/views/Onboarding/PeripheralAppsProductUsage.vue'),
          meta: {
            title: 'Peripheral Apps - Product Usage',
          },
        },
        {
          path: 'data-warehouse',
          name: RouteName.PeripheralAppsDataWarehouse,
          component: () => import('@/views/Onboarding/PeripheralAppsDataWarehouse.vue'),
          meta: {
            title: 'Peripheral Apps - Data Warehouse',
          },
        },
        {
          path: 'kpis-and-playbooks',
          name: RouteName.PeripheralAppsKpisAndPlaybooks,
          component: () => import('@/views/Onboarding/PeripheralAppsKpisAndPlaybooks.vue'),
          meta: {
            title: 'Peripheral Apps - KPIs and Playbooks',
          },
          children: [
            {
              path: 'new-peripheral-kpi',
              name: RouteName.NewPeripheralKpi,
              ...CustomKpiRoutes,
            },
          ],
        },
        {
          path: 'oauth/:provider',
          name: RouteName.PeripheralOAuthCallback,
          component: () => import('@/views/Onboarding/PeripheralOauthCallback.vue'),
          meta: {
            title: 'OAuth Authentication',
          },
        },
      ],
    },
    {
      path: 'upsell-settings',
      component: () => import('@/views/Onboarding/UpsellSettings.vue'),
      children: [
        {
          path: '',
          name: RouteName.UpsellSettings,
          component: () => import('@/views/Onboarding/UpsellOverview.vue'),
          meta: {
            title: 'Upsell Plans - Overview',
          },
        },
        {
          path: 'commercial-plans',
          name: RouteName.CommercialPlanSettings,
          component: () => import('@/views/Onboarding/CommercialPlanSettings.vue'),
          meta: {
            title: 'Upsell Plans - Set Commercial Plans',
          },
        },
        {
          path: 'upsell-kpis',
          name: RouteName.UpsellKpiSettings,
          component: () => import('@/views/Onboarding/UpsellKpiSettings.vue'),
          meta: {
            title: 'Upsell Plans - Upsell KPIs',
          },
          children: [
            {
              ...CustomKpiRoutes,
              path: 'new-upsell-kpi',
              name: RouteName.NewUpsellKpi,
            },
          ],
        },
      ],
    },
    {
      path: 'customers-and-users',
      component: () => import('@/views/Onboarding/CustomerAndUsers.vue'),
      children: [
        {
          path: '',
          name: RouteName.CustomersAndUsers,
          component: () => import('@/views/Onboarding/CustomerAndUsersOverview.vue'),
          meta: {
            title: 'Customer & User Settings - Overview',
          },
        },
        {
          path: 'set-customer-types',
          name: RouteName.SetCustomerTypes,
          component: () => import('@/views/Onboarding/SetCustomerTypesContainer.vue'),
          meta: {
            title: 'Set Customer Types',
          },
        },
        {
          path: 'set-commercial-plans',
          name: RouteName.SetCommercialPlans,
          component: () => import('@/views/Onboarding/SetCommercialPlans.vue'),
          meta: {
            title: 'Set Commercial Plans',
          },
        },
        {
          path: 'product-mapping',
          name: RouteName.ProductMapping,
          component: () => import('@/views/Onboarding/ProductMapping.vue'),
          meta: {
            title: 'Product Mapping',
          },
        },
        {
          path: 'customer-mapping',
          name: RouteName.CustomerMapping,
          component: () => import('@/views/Onboarding/CustomerMappingContainer.vue'),
          meta: {
            title: 'Customer Mapping',
          },
        },
        {
          path: 'assign-csm-to-customers',
          name: RouteName.AssignCsmToCustomers,
          component: () => import('@/views/Onboarding/AssignCsmToCustomers.vue'),
          meta: {
            title: 'Assign CSMs to Customers',
          },
        },
        {
          path: 'set-user-types',
          name: RouteName.SetUserTypes,
          component: () => import('@/views/Onboarding/SetUserTypes.vue'),
          meta: {
            title: 'Customer Settings - Set User Types',
          },
        },
      ],
    },
  ],
};

export const OnboardingRoutesRoleBased: RouteRecordRaw = {
  path: '/onboarding',
  component: () => import('../views/Onboarding.vue'),
  name: RouteName.Onboarding,
  redirect: { name: RouteName.Profile },
  meta: {
    layout: Layout.Onboarding,
  },
  children: [
    {
      path: 'profile',
      name: RouteName.Profile,
      component: () => import('@/views/Onboarding/Profile.vue'),
      meta: {
        title: 'Your Profile',
      },
    },
    {
      path: 'business-info',
      name: RouteName.BusinessInfo,
      component: () => import('@/views/Onboarding/BusinessMetaData.vue'),
      meta: {
        title: 'Business Info',
      },
    },
    {
      path: 'zapscale-users',
      component: () => import('@/views/Onboarding/ZapscaleUsers.vue'),
      children: [
        {
          path: '',
          name: RouteName.ZapscaleUsers,
          component: () => import('@/views/Onboarding/ZapscaleUsers/Overview.vue'),
          meta: {
            title: 'Zapscale Users - Overview',
          },
        },
        {
          path: 'list',
          name: RouteName.ZapscaleUserList,
          component: () => import('@/views/Onboarding/ZapscaleUsers/ZapscaleUserList.vue'),
          meta: {
            title: 'Zapscale Users - List',
          },
          children: [
            {
              path: 'new-invitation',
              name: RouteName.ZapscaleNewInvitation,
              component: () => import('@/views/Onboarding/ZapscaleUsers/NewInvitationV2.vue'),
              props: true,
              meta: {
                title: 'Zapscale Users - Invite',
              },
            },
            {
              path: 'organization-tree',
              name: RouteName.OrganizationTree,
              component: () => import('@/views/Onboarding/ZapscaleUsers/OrganizationTree.vue'),
              props: true,
              meta: {
                title: 'Zapscale Users - Organization Tree',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'saas-app',
      component: () => import('@/views/Onboarding/SaasApp.vue'),
      children: [
        {
          path: '',
          name: RouteName.SaasApp,
          component: () => import('@/views/Onboarding/SaasAppOverview.vue'),
          meta: {
            title: 'Saas App - Overview',
          },
        },
        {
          path: 'site-map',
          name: RouteName.SaasAppSiteMap,
          component: () => import('@/views/Onboarding/SaasAppSiteMap.vue'),
          meta: {
            title: 'Saas App - SiteMap',
          },
        },
        {
          path: 'products',
          name: RouteName.SaasAppProducts,
          component: () => import('@/views/Onboarding/SaasAppProducts/SaasAppProductList.vue'),
          meta: {
            title: 'Saas App - Products',
          },
          children: [
            {
              path: 'new',
              name: RouteName.SaasAppNewProduct,
              component: () => import('@/views/Onboarding/SaasAppProducts/SaasAppNewProduct.vue'),
              props: true,
              meta: {
                title: 'Saas App - New Product',
              },
            },
            {
              path: ':id/edit',
              name: RouteName.SaasAppEditProduct,
              component: () => import('@/views/Onboarding/SaasAppProducts/SaasAppEditProduct.vue'),
              props: true,
              meta: {
                title: 'Saas App - Edit Product',
              },
            },
          ],
        },
        {
          path: 'domains',
          name: RouteName.SaasAppDomains,
          component: () => import('@/views/Onboarding/SaasAppDomains/SaasAppDomainsList.vue'),
          meta: {
            title: 'Saas App - Domains',
          },
          children: [
            {
              path: 'new',
              name: RouteName.SaasAppNewDomain,
              component: () => import('@/views/Onboarding/SaasAppDomains/SaasAppNewDomain.vue'),
              props: true,
              meta: {
                title: 'Saas App - New Domain',
              },
            },
            {
              path: ':id/edit',
              name: RouteName.SaasAppEditDomain,
              component: () => import('@/views/Onboarding/SaasAppDomains/SaasAppEditDomian.vue'),
              props: true,
              meta: {
                title: 'Saas App - Edit Domain',
              },
            },
          ],
        },
        {
          path: 'tracker-code',
          name: RouteName.SaasAppTrackerCode,
          component: () => import('@/views/Onboarding/SaasAppTrackerCode.vue'),
          meta: {
            title: 'Saas App - Application Pages',
          },
        },
        {
          path: 'events',
          name: RouteName.SaasAppEvents,
          component: () => import('@/views/Onboarding/SaasAppEvents/SaasAppEventList.vue'),
          meta: {
            title: 'Saas App - Features',
          },
        },
        {
          path: 'user-object',
          name: RouteName.SaasAppUserObject,
          component: () => import('@/views/Onboarding/SaasAppUserObject.vue'),
          meta: {
            title: 'Saas App - User Object',
          },
        },
        {
          path: 'customers',
          name: RouteName.SaasAppCustomers,
          component: () => import('@/views/Onboarding/SaasAppCustomers.vue'),
          meta: {
            title: 'Add Customers',
          },
        },
        {
          path: 'users',
          name: RouteName.SaasAppUsers,
          component: () => import('@/views/Onboarding/SaasAppUsers.vue'),
          meta: {
            title: 'Add Users',
          },
        },
        {
          path: 'usage-and-outcome-kpis',
          name: RouteName.UsageAndOutcomeKpis,
          component: () => import('@/views/Onboarding/UsageAndOutcomeKpi.vue'),
          meta: {
            title: 'Saas App - User Object',
          },
          children: [{ ...CustomKpiRoutes, path: 'new-usage-kpi', name: RouteName.NewUsageKpi }],
        },
        {
          path: 'cs-gutfeel-kpis',
          name: RouteName.CsHandfeelKpis,
          component: () => import('@/views/Onboarding/CsHandfeelKpi.vue'),
          meta: {
            title: 'Saas App - Cs Gutfeel',
          },
        },
      ],
    },
    {
      path: 'peripheral-apps',
      component: () => import('@/views/Onboarding/PeripheralApps.vue'),
      children: [
        {
          path: '',
          name: RouteName.PeripheralApps,
          component: () => import('@/views/Onboarding/PeripheralAppsOverview.vue'),
          meta: {
            title: 'Peripheral Apps - Overview',
          },
        },
        {
          path: 'crm',
          name: RouteName.PeripheralAppsCrm,
          component: () => import('@/views/Onboarding/PeripheralAppsCrm.vue'),
          meta: {
            title: 'Peripheral Apps - CRM',
          },
        },
        {
          path: 'ticketing',
          name: RouteName.PeripheralAppsTicketing,
          component: () => import('@/views/Onboarding/PeripheralAppsBugTracking.vue'),
          meta: {
            title: 'Peripheral Apps - Bug Tracking',
          },
        },
        {
          path: 'feature-requests',
          name: RouteName.PeripheralAppsFeatureRequests,
          component: () => import('@/views/Onboarding/PeripheralAppsFeatureRequests.vue'),
          meta: {
            title: 'Peripheral Apps - Feature Requests',
          },
        },
        {
          path: 'payment-system',
          name: RouteName.PeripheralAppsPaymentSystem,
          component: () => import('@/views/Onboarding/PeripheralAppsPaymentSystem.vue'),
          meta: {
            title: 'Peripheral Apps - Payment System',
          },
        },
        {
          path: 'comm-system',
          name: RouteName.PeripheralAppsCommSystem,
          component: () => import('@/views/Onboarding/PeripheralAppsCommSystem.vue'),
          meta: {
            title: 'Peripheral Apps - Comm System',
          },
        },
        {
          path: 'product-usage',
          name: RouteName.PeripheralAppsProductUsage,
          component: () => import('@/views/Onboarding/PeripheralAppsProductUsage.vue'),
          meta: {
            title: 'Peripheral Apps - Product Usage',
          },
        },
        {
          path: 'data-warehouse',
          name: RouteName.PeripheralAppsDataWarehouse,
          component: () => import('@/views/Onboarding/PeripheralAppsDataWarehouse.vue'),
          meta: {
            title: 'Peripheral Apps - Data Warehouse',
          },
        },
        {
          path: 'kpis-and-playbooks',
          name: RouteName.PeripheralAppsKpisAndPlaybooks,
          component: () => import('@/views/Onboarding/PeripheralAppsKpisAndPlaybooks.vue'),
          meta: {
            title: 'Peripheral Apps - KPIs and Playbooks',
          },
          children: [
            {
              path: 'new-peripheral-kpi',
              name: RouteName.NewPeripheralKpi,
              ...CustomKpiRoutes,
            },
          ],
        },
        {
          path: 'oauth/:provider',
          name: RouteName.PeripheralOAuthCallback,
          component: () => import('@/views/Onboarding/PeripheralOauthCallback.vue'),
          meta: {
            title: 'OAuth Authentication',
          },
        },
      ],
    },
    {
      path: 'upsell-settings',
      component: () => import('@/views/Onboarding/UpsellSettings.vue'),
      children: [
        {
          path: '',
          name: RouteName.UpsellSettings,
          component: () => import('@/views/Onboarding/UpsellOverview.vue'),
          meta: {
            title: 'Upsell Plans - Overview',
          },
        },
        {
          path: 'commercial-plans',
          name: RouteName.CommercialPlanSettings,
          component: () => import('@/views/Onboarding/CommercialPlanSettings.vue'),
          meta: {
            title: 'Upsell Plans - Set Commercial Plans',
          },
        },
        {
          path: 'upsell-kpis',
          name: RouteName.UpsellKpiSettings,
          component: () => import('@/views/Onboarding/UpsellKpiSettings.vue'),
          meta: {
            title: 'Upsell Plans - Upsell KPIs',
          },
          children: [
            {
              ...CustomKpiRoutes,
              path: 'new-upsell-kpi',
              name: RouteName.NewUpsellKpi,
            },
          ],
        },
      ],
    },
    {
      path: 'customers-and-users',
      component: () => import('@/views/Onboarding/CustomerAndUsers.vue'),
      children: [
        {
          path: '',
          name: RouteName.CustomersAndUsers,
          component: () => import('@/views/Onboarding/CustomerAndUsersOverview.vue'),
          meta: {
            title: 'Customer & User Settings - Overview',
          },
        },
        {
          path: 'set-customer-types',
          name: RouteName.SetCustomerTypes,
          component: () => import('@/views/Onboarding/SetCustomerTypesContainer.vue'),
          meta: {
            title: 'Set Customer Types',
          },
        },
        {
          path: 'set-commercial-plans',
          name: RouteName.SetCommercialPlans,
          component: () => import('@/views/Onboarding/SetCommercialPlans.vue'),
          meta: {
            title: 'Set Commercial Plans',
          },
        },
        {
          path: 'product-mapping',
          name: RouteName.ProductMapping,
          component: () => import('@/views/Onboarding/ProductMapping.vue'),
          meta: {
            title: 'Product Mapping',
          },
        },
        {
          path: 'customer-mapping',
          name: RouteName.CustomerMapping,
          component: () => import('@/views/Onboarding/CustomerMappingContainer.vue'),
          meta: {
            title: 'Customer Mapping',
          },
        },
        {
          path: 'assign-csm-to-customers',
          name: RouteName.AssignCsmToCustomers,
          component: () => import('@/views/Onboarding/AssignCsmToCustomers.vue'),
          meta: {
            title: 'Assign CSMs to Customers',
          },
        },
        {
          path: 'set-user-types',
          name: RouteName.SetUserTypes,
          component: () => import('@/views/Onboarding/SetUserTypes.vue'),
          meta: {
            title: 'Customer Settings - Set User Types',
          },
        },
        {
          path: 'campaign-exclusions',
          name: RouteName.CampaignExclusions,
          component: () => import('@/views/Onboarding/BlacklistUserEmail.vue'),
          meta: {
            title: 'Blacklist Users',
          },
        },
        {
          path: 'campaign-email-settings',
          name: RouteName.CampaignEmailSettings,
          component: () => import('@/views/Onboarding/CampaignEmailSendingSettings.vue'),
          meta: {
            title: 'Campaign Email Settings',
          },
        },
      ],
    },
    {
      path: 'deal-configurations',
      component: () => import('@/views/Onboarding/DealConfigurations.vue'),
      children: [
        {
          path: 'deal-forecast',
          name: RouteName.DealForecast,
          component: () => import('@/views/Onboarding/DealConfigurations/DealForecast.vue'),
          meta: {
            title: 'Deal Forecast',
          },
        },
      ],
    },
  ],
};
